define('diesel/models/backup', ['exports', 'ember-data', 'diesel/models/base'], function (exports, _emberData, _dieselModelsBase) {
  exports['default'] = _dieselModelsBase['default'].extend({
    awsRegion: _emberData['default'].attr('string'),
    createdAt: _emberData['default'].attr('iso-8601-timestamp'),
    size: _emberData['default'].attr('number'),

    account: _emberData['default'].belongsTo('account', { async: true }),
    database: _emberData['default'].belongsTo('database', { async: true }),
    copiedFrom: _emberData['default'].belongsTo('backup', {
      inverse: 'copies',
      async: false
    }),
    copies: _emberData['default'].hasMany('backup', { async: true, inverse: 'copiedFrom' })
  });
});