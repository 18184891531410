define('diesel/utils/can', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = can;

  function can(user, scope, permittablePromise) {
    if (scope === 'manage' && !user.get('verified')) {
      return _ember['default'].RSVP.resolve(false);
    }

    var permittable = undefined;

    return new _ember['default'].RSVP.resolve(permittablePromise).then(function (resolvedPermittable) {
      _ember['default'].assert('Must pass a parameter that implements `permitsRole`', !!resolvedPermittable.permitsRole);
      permittable = resolvedPermittable;
      return user.get('roles');
    }).then(function (roles) {
      return roles.map(function (role) {
        return permittable.permitsRole(role, scope);
      }).indexOf(true) > -1;
    });
  }
});