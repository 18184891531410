define('diesel/organization/users/edit/route', ['exports', 'ember', 'diesel/utils/changeset'], function (exports, _ember, _dieselUtilsChangeset) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model(params) {
      var context = this.modelFor('organization');
      return context.get('users').findBy('id', params.user_id);
    },

    afterModel: function afterModel(user) {
      return user.get('roles');
    },

    setupController: function setupController(controller, model) {
      var context = this.modelFor('organization');
      controller.set('model', model);
      controller.set('authorizationContext', context);

      var changeset = _dieselUtilsChangeset['default'].create({
        key: function key(keyData) {
          return keyData.organizationRole.get('id');
        },
        initialValue: function initialValue(keyData) {
          var user = keyData.user;
          var organizationRole = keyData.organizationRole;
          return user.get('roles').includes(organizationRole);
        }
      });
      controller.set('changeset', changeset);
    },

    actions: {
      cancel: function cancel() {
        this.transitionTo('organization.users');
      },

      remove: function remove(user) {
        var _this = this;

        var context = this.modelFor('organization');

        var _context$getProperties = context.getProperties('organization');

        var organization = _context$getProperties.organization;

        var confirmMessage = 'You are about to remove ' + user.get('name') + ' from ' + organization.get('name') + '. Are you sure?';

        if (!window.confirm(confirmMessage)) {
          return;
        }

        var message = user.get('name') + ' removed from ' + organization.get('name');

        user.set('organizationId', organization.get('id'));
        user.destroyRecord().then(function () {
          _this.transitionTo('organization.users');
          _ember['default'].get(_this, 'flashMessages').success(message);
        });
      },

      save: function save(changeset) {
        var _this2 = this;

        var stagedCreations = [];
        var stagedDeletions = [];

        changeset.forEachValue(function (keyData, initialValue, value) {
          if (initialValue === value) {
            return;
          }

          var user = _this2.currentModel;
          var userUrl = user.get('links.self');
          var role = keyData.organizationRole;

          if (value) {
            stagedCreations.push(function () {
              return _this2.store.createRecord('membership', { userUrl: userUrl, user: user, role: role }).save().then(function () {
                return user.get('roles').addObject(role);
              });
            });
          } else {
            stagedDeletions.push(function () {
              return role.get('memberships').then(function (memberships) {
                var userMembership = memberships.findBy('user', user);
                _ember['default'].assert('A user membership could not be found for user id "' + user.get('id') + '"\n                           and role name ' + role.get('name'), !!userMembership);
                return userMembership.destroyRecord().then(function () {
                  return user.get('roles').removeObject(role);
                });
              });
            });
          }
        });

        _ember['default'].RSVP.all(stagedCreations.map(function (f) {
          return f.apply();
        })).then(function () {
          return _ember['default'].RSVP.all(stagedDeletions.map(function (f) {
            return f.apply();
          }));
        }).then(function () {
          var user = _this2.currentModel;
          var message = user.get('name') + ' updated';
          _ember['default'].get(_this2, 'flashMessages').success(message);
          _this2.transitionTo('organization.users');
        })['catch'](function (e) {
          var message = 'An error occurred: ' + e.message;
          _ember['default'].get(_this2, 'flashMessages').danger(message);
        });
      }
    }
  });
});