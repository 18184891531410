define('diesel/organization/users/index/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      var organization = this.modelFor('organization');
      return _ember['default'].RSVP.hash({
        organization: organization,
        users: organization.get('users').reload()
      });
    }
  });
});