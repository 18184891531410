define('diesel/organization/roles/type/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    confirmationModal: _ember['default'].inject.service(),
    label: _ember['default'].computed('type', function () {
      return ({
        platform: 'Deploy', compliance: 'Comply'
      })[this.get('role.type')];
    }),
    actions: {
      inviteTo: function inviteTo(role) {
        var organization = this.get('organization');
        this.transitionToRoute('organization.invite', organization, { queryParams: { role: role } });
      }
    }
  });
});