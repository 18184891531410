define("diesel/settings/route", ["exports", "ember", "diesel/utils/location"], function (exports, _ember, _dieselUtilsLocation) {
  exports["default"] = _ember["default"].Route.extend({
    renderTemplate: function renderTemplate() {
      this._super.apply(this, arguments);
      this.render("sidebars/settings", {
        into: "settings",
        outlet: "sidebar"
      });
    },

    actions: {
      onOrgSwitch: function onOrgSwitch(organization) {
        var _this = this;

        var message = "Switched to " + organization.get("name");
        _ember["default"].get(this, "flashMessages").success(message);
        return this.authorization.load().then(function () {
          return _this.transitionTo("organization.users.index", organization);
        });
      },

      reauthenticate: function reauthenticate() {
        var _this2 = this;

        //This is from logout/route.js
        return this.session.close("application", {
          token: this.get("session.token")
        })["catch"](function (e) {
          // e will be a jqXHR error object (not a DS.AdapterError)
          // because the application torii adapter makes a jQuery ajax call
          // to close the session
          if (e.responseJSON && e.responseJSON.error === "expired_token") {
            // If the user's token has expired, then we don't care to log them
            // out "further".
            return;
          }
          throw e;
        }).then(function () {
          return _this2.get("analytics").logout();
        }).then(function () {
          return _dieselUtilsLocation["default"].replaceAndWait("/");
        })["catch"](function (e) {
          var message = e.responseJSON && e.responseJSON.message || e.message || "An unexpected error occurred.";
          _ember["default"].get(_this2, "flashMessages").danger(message);
        });
      }
    }
  });
});