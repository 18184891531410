define("diesel/components/scim-group-edit/component", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    tagName: 'tr',
    group: null,
    organizationRoles: [],
    editing: false,

    role: _ember["default"].computed("group", "group.role", function () {
      var group = this.get("group");
      return group.get("role");
    }),

    actions: {
      edit: function edit() {
        this.set("editing", true);
      },

      cancel: function cancel() {
        this.get('group').rollbackAttributes();
        this.set("editing", false);
      },

      save: function save() {
        var _this = this;

        this.group.save().then(function () {
          _this.set("editing", false);
        });
      }
    }
  });
});