define('diesel/components/verification-code-reset/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['banner-button-alert'],
    store: _ember['default'].inject.service(),

    // We only show the verification banner if we have a user. In most cases we
    // will, but this avoids flicker when we e.g. log the user out (which will
    // nullify the user).
    userNeedsVerification: _ember['default'].computed.not("user.verified"),
    hasUser: _ember['default'].computed.notEmpty("user"),
    showVerificationBanner: _ember['default'].computed.and("hasUser", "userNeedsVerification"),

    sending: false,
    sent: false,
    error: null,
    isDisabled: _ember['default'].computed.or('sending', 'sent'),

    actions: {
      resendVerification: function resendVerification() {
        var _this = this;

        this.setProperties({ sending: true, error: null });

        this.get("store").createRecord("email-verification-challenge", {
          user: this.get("user"),
          email: this.get("user.email")
        }).save().then(function () {
          _this.set('sent', true);
        }, function (e) {
          _this.set('error', e);
        })['finally'](function () {
          _this.set('sending', false);
        });
      }
    }
  });
});