define("diesel/models/container", ["exports", "ember", "ember-data", "diesel/models/base"], function (exports, _ember, _emberData, _dieselModelsBase) {
  exports["default"] = _dieselModelsBase["default"].extend({
    release: _emberData["default"].belongsTo('release', { async: true }),
    dockerName: _emberData["default"].attr('string'),
    layer: _emberData["default"].attr('string'),
    memoryLimit: _emberData["default"].attr('number'),

    cpuLimitPct: _ember["default"].computed('memoryLimit', function () {
      var memoryLimit = this.get('memoryLimit');

      if (!memoryLimit) {
        return memoryLimit;
      }

      return memoryLimit / 1024 / 4 * 100;
    })
  });
});