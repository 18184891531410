define('diesel/components/attachment-download-link/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['attachment-download-link'],
    isVisible: _ember['default'].computed.bool('attachment'),
    icon: _ember['default'].computed('attachment.fileOriginalName', function () {
      if (this.get('attachment.fileOriginalName')) {
        return 'download';
      }

      return 'external-link';
    }),
    url: _ember['default'].computed('attachment.fileOriginalName', 'attachment.downloadURL', 'attachment.fileURL', function () {
      if (this.get('attachment.fileOriginalName')) {
        return this.get('attachment.downloadURL');
      }
      return this.get('attachment.fileURL');
    }),
    name: _ember['default'].computed('attachment.fileOriginalName', 'attachment.downloadURL', 'attachment.fileURL', function () {
      if (this.get('attachment.fileOriginalName')) {
        return this.get('attachment.fileOriginalName');
      }
      return this.get('attachment.fileURL');
    })
  });
});