define('diesel/models/permission', ['exports', 'ember-data', 'diesel/models/base'], function (exports, _emberData, _dieselModelsBase) {
  exports['default'] = _dieselModelsBase['default'].extend({
    scope: _emberData['default'].attr(),
    role: _emberData['default'].belongsTo('role', { async: true }),
    account: _emberData['default'].belongsTo('account', { async: true }),

    can: function can(scope) {
      return this.get('scope') === 'manage' || this.get('scope') === scope;
    }
  });
});