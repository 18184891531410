define('diesel/components/assignment-wizard/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    assessment: _ember['default'].computed('courseAssignmentState.form', function () {
      var form = this.get('courseAssignmentState.form');
      if (form) {
        return {
          questions: form.questions.map(function (q) {
            return Object.assign({}, q);
          }),
          passingScore: form.passingScore
        };
      }
    }),
    onNext: null,
    onPrevious: null,
    saveAndExit: null,
    courseAssignmentState: null,
    actions: {
      next: function next() {
        var assessment = this.get('assessment');
        this.get("onNext")(assessment);
      }
    }
  });
});