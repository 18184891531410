define('diesel/components/delimited-list/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'div',
    classNames: 'delimited-list',

    count: null,
    list: null,
    listKey: null,

    hasValues: _ember['default'].computed.gt('values.length', 0),

    values: _ember['default'].computed('list.[]', function () {
      var count = this.getWithDefault('count', this.get('list.length'));
      var key = this.get('listKey');

      var result = this.get('list.content').mapBy(key).slice(0, count).join(', ');
      if (this.get('difference') !== 0) {
        result += ', ';
      }
      return result;
    }),

    difference: _ember['default'].computed('list.[]', function () {
      return Math.max(this.get('list.length') - this.get('count'), 0);
    })
  });
});