define("diesel/mixins/controllers/service-metrics", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Mixin.create({
    targetContainers: _ember["default"].computed.filter("model.service.currentRelease.containers", function (container) {
      return container.get("layer") === this.getTargetLayer();
    }),

    horizonIsOneHour: _ember["default"].computed.equal("model.uiState.dataHorizon", "1h"),
    horizonIsOneDay: _ember["default"].computed.equal("model.uiState.dataHorizon", "1d"),

    actions: {
      setOneHourHorizon: function setOneHourHorizon() {
        this.setHorizon("1h");
      },
      setOneDayHorizon: function setOneDayHorizon() {
        this.setHorizon("1d");
      },
      reload: function reload() {
        this.set("model.uiState.lastReload", Date.now());
      }
    },

    setHorizon: function setHorizon(horizon) {
      this.set("model.uiState.dataHorizon", horizon);
    }
  });
});