define('diesel/models/log-drain', ['exports', 'ember-data', 'diesel/models/base', 'ember', 'diesel/mixins/models/provisionable', 'diesel/mixins/models/statuses'], function (exports, _emberData, _dieselModelsBase, _ember, _dieselMixinsModelsProvisionable, _dieselMixinsModelsStatuses) {

  function drainTypeMatcher(drainType) {
    return _ember['default'].computed.equal('drainType', drainType);
  }

  exports['default'] = _dieselModelsBase['default'].extend(_dieselMixinsModelsProvisionable['default'], {
    handle: _emberData['default'].attr('string'),

    drainHost: _emberData['default'].attr('string'),
    drainPort: _emberData['default'].attr('string'),
    drainType: _emberData['default'].attr('string', { defaultValue: 'syslog_tls_tcp' }),
    drainUsername: _emberData['default'].attr('string'),
    drainPassword: _emberData['default'].attr('string'),
    url: _emberData['default'].attr('string'),
    loggingToken: _emberData['default'].attr('string'),

    drainApps: _emberData['default'].attr('boolean'),
    drainDatabases: _emberData['default'].attr('boolean'),
    drainEphemeralSessions: _emberData['default'].attr('boolean'),
    drainProxies: _emberData['default'].attr('boolean'),

    reloadOn: [_dieselMixinsModelsStatuses['default'].PENDING, _dieselMixinsModelsStatuses['default'].PROVISIONING, _dieselMixinsModelsStatuses['default'].DEPROVISIONING],

    database: _emberData['default'].belongsTo('database', { async: true }),
    account: _emberData['default'].belongsTo('account', { async: true }),

    isTail: drainTypeMatcher('tail'),
    isElasticsearch: _ember['default'].computed.or('isLegacyElasticsearch', 'isCurrentElasticsearch'),
    isHttps: _ember['default'].computed.or('isLegacyHttps', 'isCurrentHttps'),
    isSyslog: drainTypeMatcher('syslog_tls_tcp'),

    isLegacyHttps: drainTypeMatcher('https'),
    isCurrentHttps: drainTypeMatcher('https_post'),

    isLegacyElasticsearch: drainTypeMatcher('elasticsearch'),
    isCurrentElasticsearch: drainTypeMatcher('elasticsearch_database'),

    prettyDrainType: _ember['default'].computed('drainType', function () {
      var drainType = this.get('drainType');

      return ({
        syslog_tls_tcp: 'Syslog over TCP / TLS',
        elasticsearch_database: 'Elasticsearch',
        https_post: 'HTTPS',
        tail: 'Aptible CLI',
        https: 'HTTPS',
        elasticsearch: 'Elasticsearch'
      })[drainType] || drainType;
    })
  });
});