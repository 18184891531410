define("diesel/settings/requires-elevation/admin/route", ["exports", "ember", "diesel/utils/tokens"], function (exports, _ember, _dieselUtilsTokens) {

  var clearPassword = function clearPassword(user) {
    user.set("password", null);
  };

  var clearOtp = function clearOtp(user) {
    user.set("otpToken", null);
    user.get("currentOtpConfiguration").then(function (otpConfiguration) {
      if (otpConfiguration) {
        otpConfiguration.set("otpUri", null);
      }
    });
  };

  exports["default"] = _ember["default"].Route.extend({
    modalDisplay: _ember["default"].inject.service(),

    model: function model() {
      var user = this.session.get("currentUser");

      return _ember["default"].Object.create({
        workingPassword: "",
        workingPasswordConfirmation: "",
        workingEmail: "",
        workingOtpConfiguration: user.get("currentOtpConfiguration"),
        otpToken: "",
        showOtpRecoveryCodes: false,
        showOtpSecret: false,
        user: user
      });
    },

    promptForSessionLogout: function promptForSessionLogout() {
      var m = 'Do you want to log out all other sessions? ' + '(if unsure, we recommend you do so by clicking OK)';
      if (!confirm(m)) {
        return;
      }
      return this.revokeAllAccessibleTokens();
    },

    revokeAllAccessibleTokens: function revokeAllAccessibleTokens() {
      var _this = this;

      // This page is protected by elevation, so we might have two tokens: a
      // persisted one that's going to be used once we exit this page, and an
      // ephemeral elevated token that's in-use right now. We want to preserve
      // both of those.
      return _ember["default"].RSVP.hash({
        persistedToken: (0, _dieselUtilsTokens.getPersistedToken)(),
        sessionToken: this.get("session.token.links.self")
      }).then(function (tokens) {
        return (0, _dieselUtilsTokens.revokeAllAccessibleTokens)({
          exceptTokenHrefs: [tokens.persistedToken._links.self.href, tokens.sessionToken] });
      }).then(function (response) {
        var m = "Revoked " + response.revoked + " token(s). " + 'It may take up to a minute for all sessions to effectively be logged out.';
        _ember["default"].get(_this, 'flashMessages').success(m);
      });
    },

    handleApiError: function handleApiError(e) {
      var message = e.message || 'An unexpected error occurred.';
      _ember["default"].get(this, 'flashMessages').danger(message);
    },

    clearCredentials: function clearCredentials() {
      var user = this.currentModel.get("user");

      if (user.get('isDeleted')) {
        // In test, the user will (most likely) be deleted before the route is
        // destroyed.
        return;
      }

      // TODO this will leave the user in a dirty state.
      clearPassword(user);
      clearOtp(user);
    },

    open2FAModal: function open2FAModal() {
      var model = this.currentModel;
      var modalDisplay = this.get('modalDisplay');
      var boundToggleOtp = modalDisplay.modalAction(this, 'toggleOtp', {
        dismissAfter: false
      });
      modalDisplay.showModal('modal-2fa', { model: model }, {
        actions: { toggleOtp: boundToggleOtp }
      });
    },

    actions: {
      changePassword: function changePassword() {
        var _this2 = this;

        var user = this.currentModel.get("user");
        var newPassword = this.currentModel.get("workingPassword");
        var newPasswordConfirmation = this.currentModel.get("workingPasswordConfirmation");

        if (!newPassword) {
          _ember["default"].get(this, 'flashMessages').danger("Password can't be empty.");
          return;
        }

        if (newPassword !== newPasswordConfirmation) {
          _ember["default"].get(this, 'flashMessages').danger("Passwords don't match.");
          return;
        }

        user.set("password", newPassword);
        user.save().then(function () {
          _this2.currentModel.set("workingPassword", "");
          _this2.currentModel.set("workingPasswordConfirmation", "");
          clearPassword(user);
          _ember["default"].get(_this2, 'flashMessages').success('Password updated');
        }).then(function () {
          return _this2.promptForSessionLogout();
        })["catch"](function (e) {
          _this2.handleApiError(e);
        });
      },

      changeEmail: function changeEmail() {
        var _this3 = this;

        var user = this.currentModel.get("user");
        var newEmail = this.currentModel.get("workingEmail");

        if (newEmail === user.get("email")) {
          var msg = newEmail + " is already your email address";
          _ember["default"].get(this, "flashMessages").warning(msg);
          return;
        }

        var challenge = this.store.createRecord("email-verification-challenge", {
          user: user,
          email: newEmail
        });

        this.controller.set("emailIsSaving", true);

        challenge.save().then(function (emailVerificationChallenge) {
          var m = "A verification email has been sent to " + emailVerificationChallenge.get("email");
          _ember["default"].get(_this3, 'flashMessages').success(m);
        }, function (e) {
          challenge.rollbackAttributes();
          throw e;
        })["catch"](function (e) {
          _this3.handleApiError(e);
        })["finally"](function () {
          _this3.controller.set("emailIsSaving", false);
        });
      },

      resetOtp: function resetOtp() {
        var _this4 = this;

        var user = this.currentModel.get("user");

        var otpConfiguration = this.store.createRecord('otp-configuration', { user: user });
        otpConfiguration.save().then(function () {
          // Add the OTP configuration on the user, but don't save it just yet.
          // It'll be saved if we make another request, with the OTP token.
          _this4.currentModel.set("workingOtpConfiguration", otpConfiguration);
          _this4.open2FAModal();
        })["catch"](function (e) {
          _this4.handleApiError(e);
        });
      },

      downloadBackupCodes: function downloadBackupCodes() {
        this.currentModel.set('showOtpRecoveryCodes', true);
        this.open2FAModal();
      },

      toggleOtp: function toggleOtp() {
        var _this5 = this;

        var user = this.currentModel.get("user");
        var otpWasEnabled = user.get("otpEnabled");

        if (otpWasEnabled) {
          var confirmMsg = "\nAre you sure you want to disable 2-factor authentication?\n";
          if (!confirm(confirmMsg)) {
            return false;
          }
        }

        user.setProperties({
          otpEnabled: !otpWasEnabled,
          otpToken: this.currentModel.get("otpToken"),
          currentOtpConfiguration: this.currentModel.get("workingOtpConfiguration")
        });
        return user.save().then(function () {
          clearOtp(user);
          _this5.currentModel.setProperties({
            otpToken: null,
            showOtpSecret: false
          });
          _ember["default"].get(_this5, 'flashMessages').success("2FA is now " + (otpWasEnabled ? 'disabled' : 'enabled') + ".");
          return !otpWasEnabled;
        }).then(function (newOtpStatus) {
          if (!newOtpStatus) {
            return;
          }
          _this5.currentModel.set('showOtpRecoveryCodes', true);
          return _this5.promptForSessionLogout();
        })["catch"](function (e) {
          _this5.handleApiError(e);
          user.set("otpEnabled", otpWasEnabled);
          // Disabling happens outside of the 2FA modal.
          // If enabling and an error occurs, be sure the modal remains
        });
      },

      revokeAllAccessibleTokens: function revokeAllAccessibleTokens() {
        // NOTE: This action technically does not require elevation in the API,
        // but the account page is the most natural place to put it.
        return this.revokeAllAccessibleTokens();
      },

      openEmailVerificationChallengesModal: function openEmailVerificationChallengesModal() {
        var _this6 = this;

        var modalDisplay = this.get('modalDisplay');
        return this.currentModel.get("user.emailVerificationChallenges").then(function (r) {
          var emailVerificationChallenges = r;
          var boundAction = modalDisplay.modalAction(_this6, 'revokeEmailVerificationChallenge', {
            dismissAfter: false
          });
          modalDisplay.showModal('modal-email-verification-challenges', { emailVerificationChallenges: emailVerificationChallenges, model: _this6.currentModel }, {
            actions: {
              revokeEmailVerificationChallenge: boundAction
            }
          });
        });
      },

      revokeEmailVerificationChallenge: function revokeEmailVerificationChallenge(challenge) {
        var _this7 = this;

        var challengeEmail = challenge.get("email");
        return challenge.destroyRecord().then(function () {
          var m = "Revoked email verification for " + challengeEmail;
          _ember["default"].get(_this7, 'flashMessages').success(m);
        });
      },

      openU2fDeviceModal: function openU2fDeviceModal() {
        var user = this.currentModel.get("user");
        var newDevice = this.store.createRecord('u2f-device', { user: user });
        var modalDisplay = this.get('modalDisplay');
        modalDisplay.showModal('modal-create-u2f-device', { newDevice: newDevice });
      },

      destroyU2fDevice: function destroyU2fDevice(device) {
        var _this8 = this;

        device.destroyRecord().then(function () {
          var m = "Removed Security Key: " + device.get('name');
          _ember["default"].get(_this8, 'flashMessages').success(m);
        });
      },

      openWebauthnDeviceModal: function openWebauthnDeviceModal() {
        var user = this.currentModel.get("user");
        var newDevice = this.store.createRecord('webauthn-device', { user: user });
        var modalDisplay = this.get('modalDisplay');
        modalDisplay.showModal('modal-create-webauthn-device', { newDevice: newDevice });
      },

      destroyWebauthnDevice: function destroyWebauthnDevice(device) {
        var _this9 = this;

        device.destroyRecord().then(function () {
          var m = "Removed Security Key: " + device.get('name');
          _ember["default"].get(_this9, 'flashMessages').success(m);
        });
      },

      willTransition: function willTransition() {
        return this.clearCredentials();
      }
    }
  });
});