define("diesel/organization/users/index/controller", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Controller.extend({
    sortBy: ["email:asc", "name:asc"],
    sortedMembers: _ember["default"].computed.sort("model.users", "sortBy"),
    totalUsersCount: _ember["default"].computed.alias("model.users.length"),
    currentPage: 0,
    currentUsers: _ember["default"].computed("currentPage", function () {
      var start = this.get("currentPage") * 20;
      var end = start + 20;
      return this.get("sortedMembers").slice(start, end);
    }),
    maxPage: _ember["default"].computed("totalUsersCount", function () {
      return Math.ceil(this.get("totalUsersCount") / 20);
    }),
    hasMultiplePages: _ember["default"].computed.gt("maxPage", 1),
    nextPage: _ember["default"].computed("currentPage", function () {
      return this.get("currentPage") + 1;
    }),
    prevPage: _ember["default"].computed("currentPage", function () {
      return this.get("currentPage") - 1;
    }),
    pages: _ember["default"].computed("maxPage", function () {
      var pages = [];
      for (var i = 0; i < this.get("maxPage"); i++) {
        pages.push({ number: i, displayNumber: i + 1 });
      }
      return pages;
    }),
    actions: {
      nextPage: function nextPage() {
        this.set("currentPage", this.get("nextPage"));
      },
      changePage: function changePage(page) {
        this.set("currentPage", page);
      },
      prevPage: function prevPage() {
        this.set("currentPage", this.get("prevPage"));
      }
    },
    showNext: _ember["default"].computed("currentPage", "maxPage", function () {
      return this.get("currentPage") + 1 < this.get("maxPage");
    }),
    showPrevious: _ember["default"].computed("currentPage", function () {
      return this.get("currentPage") > 0;
    })
  });
});