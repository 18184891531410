define('diesel/components/stack-organization-accounts/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    authorizationContext: null,
    stack: null,

    filteredAccounts: _ember['default'].computed('authorizationContext.accounts.[]', 'stack.id', function () {
      // We watch stack.id, but access stack.links.self, because we can't
      // watch the latter: If we did, then all hell breaks loose and Ember does
      // not work anyore.
      var stackHref = this.get('stack.links.self');
      return this.get('authorizationContext.accounts').filterBy('links.stack', stackHref);
    }),

    sortBy: ['handle:asc'],
    sortedFilteredAccounts: _ember['default'].computed.sort('filteredAccounts', 'sortBy')
  });
});