define('diesel/utils/fetch-all-pages', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = fetchAllPages;
  var _Ember$RSVP = _ember['default'].RSVP;
  var all = _Ember$RSVP.all;
  var resolve = _Ember$RSVP.resolve;
  var pluralize = _ember['default'].String.pluralize;

  function fetchAllPages(store, parent, modelName) {
    var relationshipName = pluralize(modelName);
    var modelPromise = parent ? parent.get(relationshipName) : store.query(modelName, {});

    return resolve(modelPromise).then(function (items) {
      var resolvedItems = items.toArray();
      var promises = [];
      var metadata = items.get('meta') || {};
      var currentPage = metadata.current_page;
      var totalPages = Math.ceil(metadata.total_count / metadata.per_page);

      if (!currentPage || !totalPages) {
        return items;
      }

      while (currentPage < totalPages) {
        var query = {
          page: ++currentPage
        };

        if (parent) {
          query[parent.constructor.modelName] = parent;
        }

        promises.push(store.query(modelName, query).then(function (models) {
          resolvedItems = resolvedItems.concat(models.toArray());
        }));
      }

      return all(promises).then(function () {
        return resolvedItems;
      });
    });
  }
});