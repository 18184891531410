define('diesel/services/authorization', ['exports', 'ember', 'diesel/utils/authorization-context', 'diesel/config/environment', 'diesel/models/account', 'diesel/models/organization', 'diesel/models/organization-profile', 'diesel/models/policy-manual', 'diesel/models/risk-assessment', 'diesel/models/role'], function (exports, _ember, _dieselUtilsAuthorizationContext, _dieselConfigEnvironment, _dieselModelsAccount, _dieselModelsOrganization, _dieselModelsOrganizationProfile, _dieselModelsPolicyManual, _dieselModelsRiskAssessment, _dieselModelsRole) {

  // Use this service to consolidate logical functions that require several models
  // in order to make a decision.  A good example of this is permission and role
  // checks.

  var PERMITTABLE_ANY_ORGANIZATION_CONTEXT = Object.create(null);

  exports['default'] = _ember['default'].Service.extend({
    store: _ember['default'].inject.service(),
    session: _ember['default'].inject.service(),
    features: _dieselConfigEnvironment['default'].featureFlags,

    load: function load() {
      var _this = this;

      var _getProperties = this.getProperties('session', 'store');

      var session = _getProperties.session;
      var store = _getProperties.store;

      var currentUser = session.get('currentUser');
      var currentOrg = session.get('currentOrganization') || this.get('currentOrganization');
      // If logged in via SSO, then persisted org won't be available. Just use current org.
      var selectedOrg = session.get('currentOrganization') || session.get('currentUser.selectedOrganization');

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        // Load basics
        // TODO:  We should load the current users memberships here too, however
        // that requires an auth change.  There is curretnly no link to memberships from a user
        // Even better, we should embed memberships for roles and users.
        _ember['default'].RSVP.hash({
          inaccessibleOrganizations: store.query('organization', { reauthenticate: true }),
          organizations: store.findAll('organization'),
          currentUserRoles: session.get('currentUser.roles'),
          currentUser: session.get('currentUser'),
          selectedOrganization: selectedOrg,
          currentOrganization: currentOrg,
          ssoLogin: !!session.get('currentOrganization')
        }).then(function (initialParams) {
          _this.setProperties(initialParams);

          //For each organization, eagerly load their entire context
          var organizations = initialParams.organizations;
          var inaccessibleOrganizations = initialParams.inaccessibleOrganizations;
          var accounts = initialParams.accounts;
          var stacks = initialParams.stacks;
          var currentUserRoles = initialParams.currentUserRoles;
          var selectedOrganization = initialParams.selectedOrganization;
          var currentOrganization = initialParams.currentOrganization;
          var ssoLogin = initialParams.ssoLogin;

          // The inaccessible organizations are in the store and get returned by the findAll promise. Filter them out here.
          organizations = organizations.filter(function (org) {
            return !inaccessibleOrganizations.includes(org);
          });
          _this.setProperties({ organizations: organizations });

          // If we don't have an existing currentOrganization
          if (!ssoLogin && !currentOrganization && organizations.length > 0) {
            // Check for a selectedOrganization from the user
            if (selectedOrganization) {
              _this.set('currentOrganization', selectedOrganization);
            } else {
              // As a fallback, pick the first returned result.
              _this.set('currentOrganization', organizations[0]);
            }
          }

          var contextPromises = organizations.map(function (organization) {
            var userContextRoles = currentUserRoles.filterBy('links.organization', organization.get('links.self'));

            return _dieselUtilsAuthorizationContext['default'].create({
              store: store, organization: organization, accounts: accounts, stacks: stacks, currentUser: currentUser, currentUserRoles: userContextRoles
            }).load();
          });

          return _ember['default'].RSVP.all(contextPromises);
        }).then(function (_organizationContexts) {
          _this.setProperties({ _organizationContexts: _organizationContexts, _isLoaded: true });
          resolve(_this);
        })['catch'](function (e) {
          return reject(e);
        });
      });
    },

    inactiveOrganizations: _ember['default'].computed('organizations', function () {
      var _this2 = this;

      return this.get('organizations').filter(function (org) {
        return org.id != _this2.get('currentOrganization.id');
      });
    }),

    organizationContexts: _ember['default'].computed('_organizationContexts', '_isLoaded', function () {
      _ember['default'].assert('You must load() this service before calling organizationContexts!', this.get('_isLoaded'));
      return this.get('_organizationContexts');
    }),

    currentContext: _ember['default'].computed('currentOrganization', 'organizationContexts', function () {
      return this.get('organizationContexts').findBy('organization.id', this.get('currentOrganization.id'));
    }),

    getContext: function getContext(organizationId) {
      return this.get('organizationContexts').findBy('organization.id', organizationId);
    },

    getContextByHref: function getContextByHref(organizationHref) {
      return this.get('organizationContexts').findBy('organization.links.self', organizationHref);
    },

    checkAbility: function checkAbility(scope, permittable) {
      var context = undefined;

      if (permittable === PERMITTABLE_ANY_ORGANIZATION_CONTEXT) {
        return this.get('organizationContexts').any(function (context) {
          return context.hasOrganizationScope(scope);
        });
      }

      if (permittable instanceof _dieselModelsPolicyManual['default'] || permittable instanceof _dieselModelsRiskAssessment['default']) {
        var organizationId = permittable.get('organizationProfile.id');
        context = this.getContext(organizationId);
        return context && context.hasGridironScope(scope);
      }

      if (permittable instanceof _dieselModelsOrganization['default']) {
        context = this.getContext(permittable.get('id'));
        return context.hasOrganizationScope(scope);
      }

      if (permittable instanceof _dieselModelsOrganizationProfile['default']) {
        context = this.getContext(permittable.get('id'));
        return context.hasGridironScope(scope);
      }

      context = this.getContextByHref(permittable.get('links.organization'));

      if (permittable instanceof _dieselModelsAccount['default']) {
        return context.hasAccountScope(scope, permittable);
      }

      if (permittable instanceof _dieselModelsRole['default']) {
        return context.hasRoleScope(scope, permittable);
      }
    },

    hasManyOrganizations: _ember['default'].computed.gt('organizationContexts.length', 1),
    hasSingleOrganization: _ember['default'].computed.equal('organizationContexts.length', 1),
    hasNoOrganizations: _ember['default'].computed.equal('organizationContexts.length', 0),
    hasInaccessibleOrganizations: _ember['default'].computed.gt('inaccessibleOrganizations.length', 0),
    hasNoAccounts: _ember['default'].computed.equal('accounts.length', 0),
    contextsWithEnclaveAccess: _ember['default'].computed.filterBy('organizationContexts', 'userHasEnclaveAccess', true),
    hasAnyEnclaveAccess: _ember['default'].computed.gt('contextsWithEnclaveAccess.length', 0),

    contextsWithEnclaveProduct: _ember['default'].computed.filterBy('organizationContexts', 'organizationHasEnclaveProduct', true),
    contextsWithGridironProduct: _ember['default'].computed.filterBy('organizationContexts', 'organizationHasGridironProduct', true),
    hasAnyOrganizationsWithEnclaveProduct: _ember['default'].computed.gt('contextsWithEnclaveProduct.length', 0),
    hasAnyOrganizationsWithGridironProduct: _ember['default'].computed.gt('contextsWithGridironProduct.length', 0),

    anyOrganizationContext: PERMITTABLE_ANY_ORGANIZATION_CONTEXT
  });
});