define('diesel/organization/admin/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    redirect: function redirect() {
      var context = this.modelFor('organization');

      if (!context.get('userIsOrganizationAdmin')) {
        var message = 'Access Denied: You must be an organization owner in order to view that page';
        _ember['default'].get(this, 'flashMessages').danger(message);
        this.transitionTo('organization.users');
      }
    }
  });
});