define('diesel/models/webauthn-challenge', ['exports', 'ember-data', 'diesel/models/base'], function (exports, _emberData, _dieselModelsBase) {
  exports['default'] = _dieselModelsBase['default'].extend({
    user: _emberData['default'].belongsTo('user', { async: true }),
    challenge: _emberData['default'].attr('string'),
    timeout: _emberData['default'].attr('number'),
    rp: _emberData['default'].attr(),
    pubKeyCredParams: _emberData['default'].attr(),
    userInfo: _emberData['default'].attr(),
    excludeCredentials: _emberData['default'].attr()
  });
});