define("diesel/components/modal-edit-backup-retention-policy/component", ["exports", "ember", "ember-validations", "diesel/mixins/components/modal-base"], function (exports, _ember, _emberValidations, _dieselMixinsComponentsModalBase) {
  exports["default"] = _ember["default"].Component.extend(_emberValidations["default"], _dieselMixinsComponentsModalBase["default"], {
    backupRetentionPolicy: null,
    oldBackupRetentionPolicy: null,

    title: "Current Policy",

    originalDaily: _ember["default"].computed.alias("backupRetentionPolicy.daily"),
    originalMonthly: _ember["default"].computed.alias("backupRetentionPolicy.monthly"),
    originalMakeCopy: _ember["default"].computed.alias("backupRetentionPolicy.makeCopy"),
    originalKeepFinal: _ember["default"].computed.alias("backupRetentionPolicy.keepFinal"),

    disableSave: _ember["default"].computed.or("isPending", "isSaving"),
    isPending: _ember["default"].computed.or("backupRetentionPolicy.isSaving", "backupRetentionPolicy.isValidating"),

    validations: {
      daily: {
        presence: true,
        numericality: {
          onlyInteger: true,
          greaterThan: 0
        }
      },
      monthly: {
        presence: true,
        numericality: {
          onlyInteger: true,
          greaterThan: 0
        }
      }
    },

    actions: {
      createBackupRetentionPolicy: function createBackupRetentionPolicy() {},

      beforeDismiss: function beforeDismiss() {
        var backupRetentionPolicy = this.get("backupRetentionPolicy");
        if (backupRetentionPolicy.id === null) {
          this.get("backupRetentionPolicy").destroyRecord();
        }
      },

      commitChanges: function commitChanges() {
        var _this = this;

        var newBackupRetentionPolicy = this.get("backupRetentionPolicy");
        var oldBackupRetentionPolicy = this.get("oldBackupRetentionPolicy");
        return newBackupRetentionPolicy.save()
        // Reload backup retention policies, since the API endpoint for create deletes
        // pre-exisitng policies.
        .then(function () {
          if (oldBackupRetentionPolicy !== null) {
            // This is clean, but a hack.
            // If we got this far, then we know the new retention policy has been saved. The API endpoint for
            // creating a retention policy purges all old retention policies, so we also know that the old policy was
            // deleted. This marks the old policy as deleted from the store so that it no longer appears in the
            // UI, without making a call to the API (which would fail, since the policy no longer exists).
            oldBackupRetentionPolicy.transitionTo('deleted.saved');
          }
        }).then(function () {
          return _this.sendAction("dismissModal");
        });
      }
    }
  });
});