define("diesel/organization/invite/route", ["exports", "ember", "ember-data"], function (exports, _ember, _emberData) {
  exports["default"] = _ember["default"].Route.extend({
    queryParams: {
      role: {
        replace: true
      }
    },

    model: function model(params, transition) {
      var context = this.modelFor("organization");
      var organization = context.get("organization");

      var options = { organization: organization };
      if (transition.queryParams.role) {
        options.role = transition.queryParams.role;
      }

      return this.store.createRecord("invitation", options);
    },

    afterModel: function afterModel() {
      return this.modelFor("organization").get("roles");
    },

    setupController: function setupController(controller, model) {
      var context = this.modelFor("organization");
      var organization = context.get("organization");

      if (!model.get("role.id")) {
        model.set("role", context.get("editableRoles").find(function (role) {
          return role.get('type') === 'compliance_user';
        }));
      }

      controller.set("model", model);
      controller.set("authorizationContext", context);
      controller.set("organization", organization);
    },

    resetController: function resetController(controller) {
      controller.set("success", null);
    },

    actions: {
      willTransition: function willTransition() {
        this.currentModel.rollbackAttributes();
      },

      invite: function invite() {
        var _this = this;

        var invitation = this.controller.get("model");

        // Default to setting the origin to comply
        if (invitation.get('role.isPlatformRole')) {
          invitation.set('origin', null);
        } else {
          invitation.set('origin', 'comply');
        }

        this.controller.set("success", null);
        invitation.save().then(function () {
          _this.controller.set("success", true);

          var newInvite = _this.store.createRecord("invitation");
          _this.controller.set("model", newInvite);
          _this.transitionTo("organization.pending-invitations");
          var successMessage = "Invitation sent to " + invitation.get("email");
          _ember["default"].get(_this, "flashMessages").success(successMessage);
        })["catch"](function (e) {
          if (e instanceof _emberData["default"].InvalidError) {
            // no-op, will be displayed in template
          } else {
              throw e;
            }
        });
      },

      cancel: function cancel() {
        this.transitionTo("organization.users.index");
      }
    }
  });
});