define("diesel/components/container-cpu-metrics/component", ["exports", "ember", "diesel/mixins/components/container-metrics"], function (exports, _ember, _dieselMixinsComponentsContainerMetrics) {
  exports["default"] = _ember["default"].Component.extend(_dieselMixinsComponentsContainerMetrics["default"], {
    init: function init() {
      this._super.apply(this, arguments);
      this.set("uiState.showCpuLimit", false);
    },

    axisLabel: "CPU usage",

    axisFormatter: function axisFormatter(v) {
      // Don't show negative values on the axis (they'd be the result of padding).
      if (v >= 0) {
        return v + " %";
      }
    },

    metric: 'cpu_pct',

    gridLines: _ember["default"].computed("minCpuLimitPct", function () {
      var minCpuLimitPct = this.get("minCpuLimitPct"),
          gridLines = [];

      if (minCpuLimitPct < Infinity) {
        gridLines.push({
          value: minCpuLimitPct,
          text: "CPU limit (" + minCpuLimitPct + " %)",
          "class": this.get('limitEnforced') ? 'enforced' : 'not-enforced'
        });
      }

      return gridLines;
    }),

    axisMax: _ember["default"].computed("minCpuLimitPct", "uiState.showCpuLimit", function () {
      var showCpuLimit = this.get("uiState.showCpuLimit"),
          minCpuLimitPct = this.get("minCpuLimitPct");

      if (showCpuLimit && minCpuLimitPct < Infinity) {
        return minCpuLimitPct;
      }
    }),

    allCpuLimits: _ember["default"].computed.mapBy("containers", "cpuLimitPct"),
    applicableCpuLimitPcts: _ember["default"].computed.filter("allCpuLimits", function (cpuLimitPct) {
      return !_ember["default"].isEmpty(cpuLimitPct) && cpuLimitPct > 0;
    }),

    minCpuLimitPct: _ember["default"].computed.min("applicableCpuLimitPcts"),
    noCpuLimit: _ember["default"].computed.equal("minCpuLimitPct", Infinity),

    limitEnforced: _ember["default"].computed.alias('stack.cpuLimits')
  });
});