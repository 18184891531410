define('diesel/home/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model(_ref) {
      var organization_id = _ref.organization_id;

      var context = this.get('authorization').getContext(organization_id);
      var organizationHref = context.get('organization.links.self');
      var store = this.get('store');

      return _ember['default'].RSVP.hash({
        context: context,
        currentProgramAndApproval: store.findAll('program').then(function (allPrograms) {
          var programs = allPrograms.filterBy('links.organization', organizationHref);
          var currentProgram = programs.get('firstObject');
          var currentApprovalId = currentProgram ? currentProgram.belongsTo('currentApproval').id() : null;

          return _ember['default'].RSVP.resolve({ currentApprovalId: currentApprovalId, currentProgram: currentProgram });
        })['catch'](function () {
          //We don't care what happens here.  Failure is OK
        })
      });
    },

    setupController: function setupController(controller, _ref2) {
      var context = _ref2.context;
      var currentProgramAndApproval = _ref2.currentProgramAndApproval;

      var authorization = this.get('authorization');
      controller.setProperties({
        context: context,
        authorization: authorization,
        currentProgramAndApproval: currentProgramAndApproval
      });
    },

    actions: {
      onOrgSwitch: function onOrgSwitch(organization) {
        var message = 'Switched to ' + organization.get("name");
        _ember['default'].get(this, "flashMessages").success(message);
        this.authorization.load();
        this.transitionTo("home", organization.id);
      },

      reauthenticate: function reauthenticate() {
        var _this = this;

        //This is from logout/route.js
        return this.session.close("application", {
          token: this.get("session.token")
        })['catch'](function (e) {
          // e will be a jqXHR error object (not a DS.AdapterError)
          // because the application torii adapter makes a jQuery ajax call
          // to close the session
          if (e.responseJSON && e.responseJSON.error === "expired_token") {
            // If the user's token has expired, then we don't care to log them
            // out "further".
            return;
          }
          throw e;
        }).then(function () {
          return _this.get("analytics").logout();
        }).then(function () {
          return Location.replaceAndWait("/");
        })['catch'](function (e) {
          var message = e.responseJSON && e.responseJSON.message || e.message || "An unexpected error occurred.";
          _ember['default'].get(_this, "flashMessages").danger(message);
        });
      }
    }
  });
});