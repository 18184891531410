define("diesel/sso/direct/route", ["exports", "ember", "diesel/config/environment", "diesel/utils/location"], function (exports, _ember, _dieselConfigEnvironment, _dieselUtilsLocation) {
  exports["default"] = _ember["default"].Route.extend({
    landing_page: null,

    beforeModel: function beforeModel(transition) {
      var landing_page = transition.queryParams.landing_page;

      this.set("landing_page", landing_page);
    },
    model: function model(params) {
      var model = _ember["default"].Object.create({
        organization_id: params.organization_id
      });
      return model;
    },
    afterModel: function afterModel(model) {
      var dashBase = !/^https?:\/\//i.test(_dieselConfigEnvironment["default"].accountBaseUri) ? "https://" + _dieselConfigEnvironment["default"].accountBaseUri : _dieselConfigEnvironment["default"].accountBaseUri;
      var failure_page = encodeURIComponent(dashBase + "/sso/failure");
      var landing_page = this.get("landing_page");
      var loginUrl = _dieselConfigEnvironment["default"].authBaseUri + "/organizations/" + model.organization_id + "/saml/login?redirect_uri=" + failure_page;
      if (landing_page) {
        _dieselUtilsLocation["default"].replace(loginUrl + ("&landing_page=" + landing_page));
      } else {
        _dieselUtilsLocation["default"].replace(loginUrl);
      }
    }
  });
});