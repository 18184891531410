define('diesel/components/modal-scim-token/component', ['exports', 'ember', 'diesel/mixins/components/modal-base'], function (exports, _ember, _dieselMixinsComponentsModalBase) {
  exports['default'] = _ember['default'].Component.extend(_dieselMixinsComponentsModalBase['default'], {
    store: _ember['default'].inject.service(),
    title: "Generate a new SCIM token",
    samlConfig: null,
    token: null,
    setTokenGenerated: function setTokenGenerated() {},

    actions: {
      generateScimToken: function generateScimToken() {
        var _this = this;

        this.set("generatingScimToken", true);
        var samlConfig = this.get("samlConfig");
        var setTokenGenerated = this.get("setTokenGenerated");

        var scimToken = this.get("store").createRecord("scimToken", {
          saml_configurations: samlConfig
        });

        scimToken.save().then(function (t) {
          var token = t.get("token");
          _this.set("token", token);
          setTokenGenerated();
        })['finally'](function () {
          _this.set("generatingScimToken", false);
          scimToken.unloadRecord();
        });
      }
    }
  });
});