define('diesel/mixins/components/container-metrics', ['exports', 'ember', 'diesel/helpers/format-utc-timestamp'], function (exports, _ember, _dieselHelpersFormatUtcTimestamp) {
  exports['default'] = _ember['default'].Mixin.create({
    metricsApi: _ember['default'].inject.service(),
    data: null,
    dataError: null,

    uiState: _ember['default'].computed('containers.length', 'data', 'dataError', function () {
      var uiState = { statusMessage: null, statusLevel: null };

      if (this.get('containers.length') === 0) {
        uiState.statusMessage = 'Waiting for containers';
        uiState.statusLevel = 'warning';
      } else if (this.get('dataError')) {
        uiState.statusMessage = this.get('dataError');
        uiState.statusLevel = 'danger';
      } else if (!this.get('data')) {
        uiState.statusMessage = 'Loading data...';
        uiState.statusLevel = 'success';
      }

      return uiState;
    }),

    loadData: _ember['default'].on('init', _ember['default'].observer('containers.[]', 'horizon', 'lastReload', 'metric', function () {
      var _this = this;

      var horizon = this.get('horizon');
      var containers = this.get('containers');
      var lastReload = this.get('lastReload');
      var metric = this.get('metric');
      var metricsApi = this.get('metricsApi');

      if (containers.get('length') === 0) {
        return;
      }

      metricsApi.fetchMetrics(containers, lastReload, horizon, metric).then(function (data) {
        if (_this.isDestroyed) {
          return;
        }
        _this.set('data', data);
        _this.set('dataError', null);
      })['catch'](function (e) {
        if (_this.isDestroyed) {
          return;
        }
        _this.set('dataError', e.message);
        throw e;
      });
    })),

    grid: _ember['default'].computed("gridLines", function () {
      return _ember['default'].RSVP.hash({
        gridLines: this.get("gridLines")
      }).then(function (h) {
        return {
          y: {
            lines: h.gridLines
          }
        };
      });
    }),

    axis: _ember['default'].computed("axisLabel", "axisFormatter", "axisBottomPadding", "axisMax", function () {
      return _ember['default'].RSVP.hash({
        axisLabel: this.get("axisLabel"),
        axisFormatter: this.get("axisFormatter"),
        axisBottomPadding: this.getWithDefault("axisBottomPadding", null),
        axisMax: this.get("axisMax")
      }).then(function (h) {
        var axis = {
          x: {
            type: 'timeseries',
            tick: {
              format: _dieselHelpersFormatUtcTimestamp.formatUtcTimestamp,
              culling: {
                max: 4
              }
            }
          },
          y: {
            min: 0,
            padding: {
              bottom: h.axisBottomPadding
            },
            label: {
              text: h.axisLabel,
              position: "outer-top"
            },
            tick: {
              format: h.axisFormatter
            }
          }
        };

        if (h.axisMax) {
          axis.y.max = h.axisMax;
        }

        return axis;
      });
    })
  });
});