define('diesel/organization/users/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    setupController: function setupController(controller) {
      var context = this.modelFor('organization');

      controller.set('organizationContext', context);
      controller.set('organization', context.get('organization'));
      controller.set('organizationProfile', context.get('organizationProfile'));
    }
  });
});