define('diesel/services/webauthn', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({
    testSupport: function testSupport() {
      return new _ember['default'].RSVP.Promise(function (resolve) {
        resolve(webauthnJSON.supported());
      });
    },

    requireSupport: function requireSupport() {
      return this.testSupport().then(function (hasSupport) {
        if (!hasSupport) {
          throw new Error('Your browser does not support Security Keys');
        }
      });
    },

    create: function create(challenge) {
      var credentialOptions = {
        challenge: challenge.get('challenge'),
        rp: challenge.get('rp'),
        timeout: challenge.get('timeout'),
        pubKeyCredParams: challenge.get('pubKeyCredParams'),
        user: challenge.get('userInfo'),
        excludeCredentials: challenge.get('excludeCredentials')
      };

      return this.requireSupport().then(function () {
        return webauthnJSON.create({ "publicKey": credentialOptions }).then(function (credential) {
          return credential;
        });
      });
    },

    get: function get(credentialOptions) {
      credentialOptions['userVerification'] = 'discouraged';
      return this.requireSupport().then(function () {
        return webauthnJSON.get({ "publicKey": credentialOptions }).then(function (credential) {
          return credential;
        });
      });
    },

    supported: function supported() {
      return webauthnJSON.supported();
    }
  });
});
/* global webauthnJSON  */