define('diesel/components/save-progress/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    progress: { currentStep: 0, totalSteps: 0 },
    tagName: '',
    hasProgress: _ember['default'].computed.gt('progress.currentStep', 0),
    showProgressBar: _ember['default'].computed.and('hasProgress', 'incomplete'),
    incomplete: _ember['default'].computed.lt('progressPercent', 100),

    show: _ember['default'].computed('hasProgress', 'showLoader', function () {
      return this.get('haProgress') || this.get('showLoader');
    }),

    progressPercent: _ember['default'].computed('progress.totalSteps', 'progress.currentStep', function () {
      var _get = this.get('progress');

      var currentStep = _get.currentStep;
      var totalSteps = _get.totalSteps;

      return currentStep / totalSteps * 100;
    }),

    progressWidth: _ember['default'].computed('progressPercent', function () {
      return 'width:' + this.get('progressPercent') + '%';
    })
  });
});