define('diesel/models/service', ['exports', 'ember', 'ember-data', 'diesel/models/base'], function (exports, _ember, _emberData, _dieselModelsBase) {
  exports['default'] = _dieselModelsBase['default'].extend({
    vhosts: _emberData['default'].hasMany('vhost', { async: true }),
    account: _emberData['default'].belongsTo('account', { async: true }),
    app: _emberData['default'].belongsTo('app', { async: true }),
    database: _emberData['default'].belongsTo('database', { async: true }),

    handle: _emberData['default'].attr('string'),
    command: _emberData['default'].attr('string'),
    containerCount: _emberData['default'].attr('number'),
    containerMemoryLimitMb: _emberData['default'].attr('number'),
    processType: _emberData['default'].attr('string'),

    currentRelease: _emberData['default'].belongsTo('release', { async: true }),
    inService: _ember['default'].computed.gt('containerCount', 0),
    containerSize: _ember['default'].computed('containerMemoryLimitMb', function () {
      return this.get('containerMemoryLimitMb') || 1024;
    }),

    containerSizeGB: _ember['default'].computed('containerMemoryLimitMb', function () {
      return this.get('containerSize') / 1024;
    }),

    usage: _ember['default'].computed('containerCount', 'containerSize', function () {
      return this.get('containerCount') * (this.get('containerSize') / 1024);
    }),

    name: _ember['default'].computed('processType', 'displayCommand', function () {
      return this.get('processType') + ' - ' + this.get('displayCommand');
    }),

    displayCommand: _ember['default'].computed('command', function () {
      return this.get('command') || 'Docker Image CMD';
    })
  });
});