define('diesel/components/operation-item/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNameBindings: [':operation-item'],
    operationAction: _ember['default'].computed('operation.type', 'operation.status', function () {
      var type = this.get('operation.type');
      var status = this.get('operation.status');

      if (status === 'running' || status === 'queued') {
        if (type === 'rebuild') {
          return 'is rebuilding';
        }
        if (type === 'backup') {
          return 'is backing up';
        }
        if (type.slice(-1) === 'e') {
          return 'is ' + type.slice(0, -1) + 'ing';
        }
        return 'is ' + type + 'ing';
      } else {
        if (type === 'execute') {
          return 'SSHed';
        }
        if (type === 'rebuild') {
          return 'rebuilt';
        }
        if (type === 'backup') {
          return 'backed up';
        }
        if (type === 'logs') {
          return 'streamed logs';
        }
        if (type === 'ps') {
          return 'listed running processes';
        }
        if (type === 'captain_comeback_restart') {
          return 'simulated an OOM event';
        }
        if (type.slice(-1) === 'e') {
          return type + 'd';
        }

        return type + 'ed';
      }

      // shouldn't get here :)
      return type; // eslint-disable-line no-unreachable
    })
  });
});