define('diesel/components/deploy-instructions/component', ['exports', 'ember', 'diesel/config/environment'], function (exports, _ember, _dieselConfigEnvironment) {
  exports['default'] = _ember['default'].Component.extend({
    externalUrls: _dieselConfigEnvironment['default'].externalUrls,

    actions: {
      deployDone: function deployDone(app) {
        this.sendAction('deployDone', app);
      }
    }
  });
});