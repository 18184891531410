define("diesel/components/impersonation-status/component", ["exports", "ember"], function (exports, _ember) {

  var makeComputedLabel = function makeComputedLabel(rwvalue, rvalue) {
    return _ember["default"].computed("superuser", "readOnlyImpersonate", function () {
      if (this.get("superuser")) {
        return rwvalue;
      }
      if (this.get("readOnlyImpersonate")) {
        return rvalue;
      }
      return "";
    });
  };

  exports["default"] = _ember["default"].Component.extend({
    tagName: "",

    label: makeComputedLabel("Read/Write", "Read Only"),
    color: makeComputedLabel("info", "info")
  });
});