define('diesel/home/controller', ['exports', 'ember', 'diesel/config/environment'], function (exports, _ember, _dieselConfigEnvironment) {
  exports['default'] = _ember['default'].Controller.extend({
    complyRootUrl: _dieselConfigEnvironment['default'].complyGrcBaseUri,
    deployRootUrl: _dieselConfigEnvironment['default'].dashboardBaseUri,
    hasOtherApps: _ember['default'].computed.gt('otherApps.length', 0),
    showOrgSwitcher: _ember['default'].computed.or('authorization.hasManyOrganizations', 'authorization.hasInaccessibleOrganizations'),
    resources: [{
      name: 'Comply Documentation',
      link: 'https://docs.aptible.com'
    }, {
      name: 'Deploy Documentation',
      link: 'https://www.aptible.com/docs'
    }, {
      name: 'Community',
      link: 'https://community.aptible.com'
    }, {
      name: 'Status',
      link: 'https://status.aptible.com'
    }, {
      name: 'Open a support ticket',
      link: 'https://contact.aptible.com'
    }, {
      name: 'Blog',
      link: 'https://www.aptible.com/blog'
    }],

    otherApps: _ember['default'].computed('context', 'currentProgramAndApproval', function () {
      var apps = [];
      var context = this.get('context');
      var orgId = context.get('organization.id');
      var showGridironClassicStuff = true || context.get('organizationHasGridironProduct');
      var currentProgramAndApproval = this.get('currentProgramAndApproval');
      var showComplianceOSStuff = currentProgramAndApproval && currentProgramAndApproval.currentApprovalId && currentProgramAndApproval.currentProgram;

      if (showGridironClassicStuff) {
        apps.push({
          name: 'Gridiron Classic',
          link: _dieselConfigEnvironment['default'].dashboardBaseUri + '/gridiron/' + orgId + '/admin'
        });

        apps.push({
          name: 'My Training Assignments',
          link: _dieselConfigEnvironment['default'].dashboardBaseUri + '/gridiron/' + orgId + '/user'
        });
      }

      if (showComplianceOSStuff) {
        apps.push({
          name: 'Compliance OS',
          link: _dieselConfigEnvironment['default'].complianceOsDashboardBaseUri
        });
      }

      return apps;
    }),

    welcomeBackLinks: _ember['default'].computed('context', function () {
      var context = this.get('context'); // model should be an auth context
      return [{
        name: context.get('organization.name') + ' Settings',
        route: 'organization',
        context: context.get('organization.id')
      }, {
        name: 'My Security Settings',
        route: 'settings.requires-elevation.admin'
      }, {
        name: 'Logout',
        route: 'logout'
      }];
    })
  });
});