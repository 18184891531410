define('diesel/components/click-to-copy/component', ['exports', 'ember', 'diesel/config/environment'], function (exports, _ember, _dieselConfigEnvironment) {

  var clickToCopyMessageTimeout = _dieselConfigEnvironment['default'].timeouts.clickToCopyMessage;

  exports['default'] = _ember['default'].Component.extend({
    actionLabel: 'Copy',
    classNames: ['click-to-copy'],
    Clipboard: Clipboard,
    errorMessage: 'Press ⌘+C to copy',
    isTooltipped: false,
    message: null,
    successMessage: 'Copied!',
    tagName: 'span',
    text: null,
    icon: null,

    dataClipboardText: _ember['default'].computed.alias('text'),

    attributeBindings: ['dataClipboardText:data-clipboard-text', 'message:data-message'],

    classNameBindings: ['isTooltipped:tooltipped'],

    setupClipboard: _ember['default'].on('didInsertElement', function () {
      var _this = this;

      var options = {};
      if (this.get('target')) {
        options.target = function () {
          return $(_this.get('target')).get(0);
        };
      }
      this.clipboard = new this.Clipboard('#' + this.elementId, options);
      this.clipboard.on('success', _ember['default'].run.bind(this, 'success'));
      this.clipboard.on('error', _ember['default'].run.bind(this, 'error'));
    }),

    success: function success() {
      this.set('message', this.successMessage);
      this.showMessage();
    },

    error: function error() {
      this.set('message', this.errorMessage);
      this.showMessage();
    },

    showMessage: function showMessage() {
      var _this2 = this;

      this.set('isTooltipped', true);

      _ember['default'].run.later(function () {
        if (_this2.isDestroyed) {
          return;
        }
        _this2.set('isTooltipped', false);
      }, clickToCopyMessageTimeout);
    }
  });
});
/*global Clipboard*/