define('diesel/models/webauthn-device', ['exports', 'ember-data', 'diesel/models/base'], function (exports, _emberData, _dieselModelsBase) {
  exports['default'] = _dieselModelsBase['default'].extend({
    user: _emberData['default'].belongsTo('user', { async: true, inverse: 'webauthnDevices' }),
    name: _emberData['default'].attr('string'),
    externalId: _emberData['default'].attr('string'),
    publicKey: _emberData['default'].attr('string'),
    createdAt: _emberData['default'].attr('date'),
    updatedAt: _emberData['default'].attr('date'),
    webauthn: _emberData['default'].attr() });
});
// tansient attribute used in registration