define('diesel/instance-initializers/u2f', ['exports'], function (exports) {
  exports.initialize = initialize;

  function initialize(appInstance) {
    // Test U2F support early so we can cache it and not have to wait.
    appInstance.lookup('service:u2f').testSupport();
  }

  exports['default'] = {
    name: 'u2f',
    initialize: initialize
  };
});