define('diesel/components/modal-create-webauthn-device/component', ['exports', 'ember', 'diesel/mixins/components/modal-base'], function (exports, _ember, _dieselMixinsComponentsModalBase) {
  exports['default'] = _ember['default'].Component.extend(_dieselMixinsComponentsModalBase['default'], {
    // Inputs
    newDevice: null,

    store: _ember['default'].inject.service(),
    webauthn: _ember['default'].inject.service(),
    flashMessages: _ember['default'].inject.service(),

    isRegistering: false,

    disableSave: _ember['default'].computed.or('isPending', 'isRegistering'),
    isPending: _ember['default'].computed.or('newDevice.isSaving', 'newDevice.isValidating'),

    title: 'Register a new Security Key',

    buttonLabel: _ember['default'].computed('disableSave', 'isRegistering', function () {
      if (!this.get('disableSave')) {
        return 'Register';
      }

      if (this.get('isRegistering')) {
        return 'Touch your Security Key to proceed';
      }

      return 'Saving ...';
    }),

    actions: {
      beforeDismiss: function beforeDismiss() {
        this.get('newDevice').rollbackAttributes();
      },

      createDevice: function createDevice() {
        var _this = this;

        var device = this.get('newDevice');
        var user = device.get('user');

        return _ember['default'].RSVP.hash({
          challenge: this.get('store').createRecord('webauthn-challenge', { user: user }).save(),
          devices: user.get('webauthn-devices')
        }).then(function (h) {
          _this.set('isRegistering', true);
          return _this.get('webauthn').create(h.challenge).then(function (attributes) {
            device.set('webauthn', attributes);
            return device.save().then(function () {
              return _this.sendAction('dismissModal');
            });
          }, function (e) {
            _this.set('registrationError', e.message);
          })['finally'](function () {
            _this.set('isRegistering', false);
          });
        })['catch'](function (e) {
          var message = e.message || 'An unexpected error occurred.';
          _ember['default'].get(_this, 'flashMessages').danger(message);
        });
      }
    }
  });
});