define('diesel/models/user', ['exports', 'ember-data', 'diesel/models/base', 'ember'], function (exports, _emberData, _dieselModelsBase, _ember) {
  exports['default'] = _dieselModelsBase['default'].extend({
    email: _emberData['default'].attr('string'),
    name: _emberData['default'].attr('string'),
    username: _emberData['default'].attr('string'),
    password: _emberData['default'].attr('string'),
    verified: _emberData['default'].attr('boolean'),
    createdAt: _emberData['default'].attr('date'),
    superuser: _emberData['default'].attr('boolean'),
    readOnlyImpersonate: _emberData['default'].attr('boolean'),
    otpEnabled: _emberData['default'].attr('boolean'),

    // Used when enabling 2FA. Set as an `attr` so that it's sent to the API.
    otpToken: _emberData['default'].attr('string'),

    // relationships
    // REVIEW: We used to have a 'token' attribute. It's unclear where this was
    // used (if at all). Do we want to create a Ember.computed 'token' field for
    // backwards compatibility?
    memberships: _emberData['default'].hasMany('membership', { async: true }),
    tokens: _emberData['default'].hasMany('token', { async: true, requireReload: true }),
    roles: _emberData['default'].hasMany('role', { async: true }),
    sshKeys: _emberData['default'].hasMany('ssh-key', { async: true }),
    whitelistMemberships: _emberData['default'].hasMany('whitelistMembership', { async: true }),

    otpConfigurations: _emberData['default'].hasMany('otp-configuration', { async: true }),
    u2fDevices: _emberData['default'].hasMany('u2f-device', { async: true }),
    webauthnDevices: _emberData['default'].hasMany('webauthn-device', { async: true }),

    emailVerificationChallenges: _emberData['default'].hasMany('email-verification-challenges', { async: true }),
    displayName: _ember['default'].computed('name', 'email', function () {
      return this.get('name') + ' <' + this.get('email') + '>';
    }),

    url: _ember['default'].computed.reads('links.self'),

    currentOtpConfiguration: _emberData['default'].belongsTo('otp-configuration', { async: true }),
    selectedOrganization: _emberData['default'].belongsTo('organization', { async: true }),

    isRoleType: function isRoleType(types, roles, organization) {
      _ember['default'].assert('You must pass types to check against', !!types);
      _ember['default'].assert('You must pass the user\'s current roles', !!roles);
      _ember['default'].assert('You must pass an organization', !!organization);

      var organizationUrl = organization.get('links.self');
      return roles.filterBy('links.organization', organizationUrl).reduce(function (prev, role) {
        return prev || types.indexOf(role.get('type')) > -1;
      }, false);
    },

    isAccountOwner: function isAccountOwner(roles, organization) {
      return this.isRoleType(['owner'], roles, organization);
    },

    isOwner: function isOwner(roles, organization) {
      var types = ['owner', 'platform_owner', 'compliance_owner'];
      return this.isRoleType(types, roles, organization);
    },

    isComplianceOwner: function isComplianceOwner(roles, organization) {
      return this.isRoleType(['compliance_owner'], roles, organization);
    },

    isPlatformOwner: function isPlatformOwner(roles, organization) {
      return this.isRoleType(['platform_owner'], roles, organization);
    },

    organizations: _ember['default'].computed('roles.@each.organization', function () {
      var organizations = {};

      this.get('roles').forEach(function (role) {
        var organization = role.get('organization');
        var organizationId = organization.get('id');
        organizations[organizationId] = organization;
      });

      return Object.keys(organizations).map(function (organizationId) {
        return organizations[organizationId];
      });
    }),

    findMembership: function findMembership(memberships) {
      return memberships.findBy('user.id', this.get('id'));
    },

    isWhitelisted: _ember['default'].computed.gt('whitelistMemberships.length', 0),
    canImpersonate: _ember['default'].computed.or('superuser', 'readOnlyImpersonate'),

    persistedU2fDevices: _ember['default'].computed.filterBy('u2fDevices', 'isNew', false),
    persistedWebauthnDevices: _ember['default'].computed.filterBy('webauthnDevices', 'isNew', false)
  });
});