define('diesel/router', ['exports', 'ember', 'diesel/config/environment', 'diesel/utils/token-bucket', 'diesel/utils/ring-buffer'], function (exports, _ember, _dieselConfigEnvironment, _dieselUtilsTokenBucket, _dieselUtilsRingBuffer) {

  var Router = _ember['default'].Router.extend({
    analytics: _ember['default'].inject.service(),
    elevation: _ember['default'].inject.service(),
    flashMessages: _ember['default'].inject.service(),
    location: _dieselConfigEnvironment['default'].locationType,
    rootURL: _dieselConfigEnvironment['default'].rootURL,
    modalDisplay: _ember['default'].inject.service(),

    init: function init() {
      this._super.apply(this, arguments);

      // Allow 20 transitions per second, burst up to 200.
      this.transitionTokenBucket = new _dieselUtilsTokenBucket['default'](200, 20);

      // Log the last 10 transitions.
      this.transitionRingBuffer = new _dieselUtilsRingBuffer['default'](10);
    },

    onBeforeTransition: _ember['default'].on('willTransition', function (transition) {
      // TODO: Can this be added to the requires-elevation route instead?
      return this.get('elevation').tryDropPrivileges(transition);
    }),

    _doTransition: function _doTransition(_targetRouteName, models, _queryParams) {
      this.transitionRingBuffer.push(_targetRouteName);

      var tokensLeft = this.transitionTokenBucket.take();
      _ember['default'].debug('_doTransition to ' + _targetRouteName + ': ' + tokensLeft + ' tokens left');

      if (!tokensLeft) {
        var lastTransitions = this.transitionRingBuffer.buffer.join(' > ');
        var m = '_doTransition: too many transitions, cycle? ' + lastTransitions;
        throw new Error(m);
      }

      return this._super(_targetRouteName, models, _queryParams);
    },

    onTransition: _ember['default'].on('didTransition', function () {
      this.get('analytics').page();
      this.get('modalDisplay').dismissModal();

      if (_dieselConfigEnvironment['default'].flashMessageDefaults.sticky) {
        this.get('flashMessages').clearMessages();
      }
    })
  });

  Router.map(function () {
    this.authenticatedRoute('requires-authorization', { path: '/', resetNamespace: true }, function () {
      this.route('settings', { resetNamespace: true }, function () {
        this.route('requires-elevation', { path: 'protected' }, function () {
          // REVIEW: We *need* to have a path for this, otherwise the index
          // redirect to profile won't work. Is /protected/ the 'right' path?
          this.route('admin');
          this.route('ssh');
        });
        this.route('profile');
        this.route('whitelist');
        this.route('cli-sso-token', {}, function () {
          this.route('display');
        });
        this.route('impersonate');
        this.route('logout');
      });
      this.route('home', { path: '/:organization_id', resetNamespace: true });
      this.route('organization', { resetNamespace: true, path: 'organizations/:organization_id' }, function () {
        this.route('users', {}, function () {
          this.route('edit', { path: ':user_id/edit' });
        });
        this.route('pending-invitations');
        this.route('role', { resetNamespace: true, path: '/roles/:role_id' }, function () {
          this.route('members');
          this.route('environments');
          this.route('settings');
        });
        this.route('roles', {}, function () {
          this.route('type', { path: ':type' });
        });
        this.route('invite');

        this.route('single-sign-on');

        this.route('admin', {}, function () {
          this.route('contact-settings');
          this.route('billing', {}, function () {
            this.route('billing-address');
            this.route('payment-methods', function () {
              this.route('new', function () {
                this.route('ach');
              });
            });
            this.route('invoices', function () {
              this.route('empty');
            });
            this.route('billing-iteration', { path: '/invoices/:billing_iteration_id' }, function () {
              this.route('charges');
              this.route('usage');
              this.route('line-items');
              this.route('payments', function () {
                this.route('new');
              });
            });
            this.route('contracts', function () {
              this.route('empty');
            });
            this.route('contract', { path: '/contracts/:contract_id' });
            this.route('credits');
            this.route('contacts', function () {
              this.route('new');
            });
          });
        });
      });
    });

    this.authenticatedRoute('elevate', { resetNamespace: true });

    this.authenticatedRoute('no-organization', { resetNamespace: true });

    this.route('sso', {}, function () {
      this.route('direct', { path: '/:organization_id' });
      this.route('failure', { path: '/failure' });
    });

    this.route('login');
    this.route('logout');

    this.route('password', {}, function () {
      this.route('reset');
      this.route('update', { path: 'update/:challenge_id/:verification_code' });
    });

    this.authenticatedRoute('verify', { path: 'verify/:challenge_id/:verification_code' });

    this.route('not-found', { path: '/*wildcard' });
  });

  exports['default'] = Router;
});