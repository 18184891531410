define('diesel/components/aptible-ability/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    authorization: _ember['default'].inject.service(),
    tagName: '',

    scope: null,
    permittable: null,
    hasAbility: _ember['default'].computed('scope', 'permittable', function () {
      var _getProperties = this.getProperties('scope', 'permittable');

      var scope = _getProperties.scope;
      var permittable = _getProperties.permittable;

      _ember['default'].assert("You must provide a scope to aptible-ability", !!scope);
      _ember['default'].assert("You must provide a permittable to aptible-ability", !!permittable);

      return this.get('authorization').checkAbility(scope, permittable);
    })
  });
});