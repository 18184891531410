define('diesel/models/disk', ['exports', 'ember-data', 'diesel/models/base', 'ember'], function (exports, _emberData, _dieselModelsBase, _ember) {
  exports['default'] = _dieselModelsBase['default'].extend({
    size: _emberData['default'].attr('number'),
    baselineIops: _emberData['default'].attr('number'),
    keyBytes: _emberData['default'].attr('number'),

    database: _emberData['default'].belongsTo('database', { async: true }),

    encryptionAlgorithm: _ember['default'].computed('database.account.isV1', 'keyBytes', function () {
      if (this.get('database.account.isV1')) {
        return 'AES-192';
      }
      return 'AES-' + this.get('keyBytes') * 8;
    })
  });
});