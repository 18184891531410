define("diesel/organization/single-sign-on/route", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Route.extend({
    modalDisplay: _ember["default"].inject.service(),

    model: function model() {
      var _this = this;

      var authorizationContext = this.modelFor("organization");
      var organization = authorizationContext.get("organization");
      var roles = authorizationContext.get("organizationComplianceUserRoles");
      var allRoles = authorizationContext.get("organizationComplianceRoles");
      var whitelist = this.get("store").query("whitelistMembership", { organization: organization }).then(function (wlMems) {
        return wlMems.map(function (wlm) {
          return wlm.get("user");
        }).sortBy("name");
      });
      var samlConfig = this.get("store").findAll("saml-configuration").then(function (configs) {
        return configs.findBy("links.organization", organization.get("links.self"));
      }).then(function (model) {
        return model ? model : _this.get("store").createRecord("saml-configuration", {
          organization: organization
        });
      });
      var autoProvisionRole = samlConfig.then(function (config) {
        return roles.find(function (role) {
          return role.id === config.get("autoProvisionRoleId");
        });
      });
      var scimRole = samlConfig.then(function (config) {
        return roles.find(function (role) {
          return role.id === config.get("scimRoleId");
        });
      });
      var scimGroups = organization.get("scimGroups");

      return _ember["default"].RSVP.hash({
        authorizationContext: authorizationContext,
        organization: organization,
        whitelist: whitelist,
        roles: roles,
        allRoles: allRoles,
        autoProvisionRole: autoProvisionRole,
        scimRole: scimRole,
        samlConfig: samlConfig,
        scimGroups: scimGroups,
        eligible: whitelist.then(function (wl) {
          return _ember["default"].RSVP.all(wl).then(function (whitelist_members) {
            var organizationUsers = authorizationContext.get("users");
            var nonWhitelist = organizationUsers.filter(function (user) {
              return !whitelist_members.includes(user);
            });
            var nonOwnerRoles = authorizationContext.get("organizationNonOwnerRoles");
            var roleUserPromises = nonOwnerRoles.map(function (roles) {
              return roles.get("users");
            });

            // within non whitelist users, check if they belong to any of the role
            return _ember["default"].RSVP.all(roleUserPromises).then(function (roleUsers) {
              var eligible = nonWhitelist.filter(function (user) {
                return roleUsers.some(function (roleUser) {
                  return roleUser.includes(user);
                });
              });
              return eligible;
            });
          });
        })
      });
    },

    actions: {
      openEditSamlModal: function openEditSamlModal() {
        var modalDisplay = this.get("modalDisplay");
        modalDisplay.showModal("modal-edit-single-sign-on", {
          newSaml: _ember["default"].get(this.currentModel, "samlConfig")
        });
      },

      remove: function remove(saml) {
        var _this2 = this;

        this.controller.set("isRemoving", true);

        saml.destroyRecord().then(function () {
          var message = "SSO provider removed";
          _ember["default"].get(_this2, "flashMessages").success(message);
          _this2.controller.set("isRemoving", false);
          _this2.refresh();
        })["catch"](function (e) {
          var message = e.message || "There was an error removing your SSO provider";
          _ember["default"].get(_this2, "flashMessages").danger(message);
          _this2.controller.set("isRemoving", false);
        });
      },

      toggleSsoEnforce: function toggleSsoEnforce() {
        var _this3 = this;

        this.controller.set("enforceIsSaving", true);
        var org = _ember["default"].get(this.currentModel, "organization");
        var ssoEnforce = org.get("ssoEnforced");
        org.set("ssoEnforced", !ssoEnforce);

        var message = org.get("ssoEnforced") ? "enabled" : "disabled";
        org.save().then(function () {
          _ember["default"].get(_this3, "flashMessages").success("Enforced SSO use is " + message);
          _this3.refresh();
        })["catch"](function (e) {
          var message = e.message || "There was an error updating your SSO enforcement settings.";
          _ember["default"].get(_this3, "flashMessages").danger(message);
        })["finally"](function () {
          _this3.controller.set("enforceIsSaving", false);
        });
      },

      toggleAutoProvision: function toggleAutoProvision() {
        var _this4 = this;

        var samlConfig = _ember["default"].get(this.currentModel, "samlConfig");
        var scimEnabled = samlConfig.get("scimEnabled");
        var autoProvision = samlConfig.get("autoProvision");
        var selectedRole = _ember["default"].get(this.currentModel, "autoProvisionRole");

        if (!autoProvision && !selectedRole) {
          var _message = "You must select a default role to provision users to!";
          _ember["default"].get(this, "flashMessages").danger(_message);
          return;
        }

        if (scimEnabled) {
          var _message2 = "You must disable SCIM provisioning to enable automatic SSO provisioning";
          _ember["default"].get(this, "flashMessages").danger(_message2);
          return;
        }

        this.controller.set("autoProvisionSaving", true);

        samlConfig.set("autoProvision", !autoProvision);

        if (autoProvision) {
          samlConfig.set("autoProvisionRoleId", null);
          _ember["default"].set(this.currentModel, "autoProvisionRole", null);
        } else {
          samlConfig.set("autoProvisionRoleId", selectedRole.get("id"));
        }

        var message = samlConfig.get("autoProvision") ? "enabled" : "disabled";
        samlConfig.save().then(function () {
          _ember["default"].get(_this4, "flashMessages").success("Auto provisioning is " + message);
          _this4.refresh();
        })["catch"](function (e) {
          var message = e.message || "There was an error enabling auto provisioning.";
          _ember["default"].get(_this4, "flashMessages").danger(message);
        })["finally"](function () {
          _this4.controller.set("autoProvisionSaving", false);
        });
      },

      openScimTokenModal: function openScimTokenModal() {
        var samlConfig = _ember["default"].get(this.currentModel, "samlConfig");
        var modalDisplay = this.get("modalDisplay");
        var controller = this.controller;

        var setTokenGenerated = function setTokenGenerated() {
          controller.set("scimToken", true);
        };
        modalDisplay.showModal("modal-scim-token", {
          samlConfig: samlConfig,
          setTokenGenerated: setTokenGenerated
        });
      },

      toggleScim: function toggleScim() {
        var _this5 = this;

        var samlConfig = _ember["default"].get(this.currentModel, "samlConfig");
        var autoProvision = samlConfig.get("autoProvision");
        var scimEnabled = samlConfig.get("scimEnabled");
        var scimToken = samlConfig.get("scimToken");
        var selectedRole = _ember["default"].get(this.currentModel, "scimRole");

        if (autoProvision) {
          var _message3 = "You must disable SSO auto provisioning to enable SCIM provisioning";
          _ember["default"].get(this, "flashMessages").danger(_message3);
          return;
        }

        if (!scimEnabled && !!scimToken) {
          var _message4 = "You must generate a token before enabling SCIM";
          _ember["default"].get(this, "flashMessages").danger(_message4);
          return;
        }

        if (!scimEnabled && !selectedRole) {
          var _message5 = "You must select a default role to assign SCIM provisioned users to!";
          _ember["default"].get(this, "flashMessages").danger(_message5);
          return;
        }

        this.controller.set("scimSettingsSaving", true);
        samlConfig.set("scimEnabled", !scimEnabled);

        if (scimEnabled) {
          samlConfig.set("scimRoleId", null);
          _ember["default"].set(this.currentModel, "scimRole", null);
        } else {
          samlConfig.set("scimRoleId", selectedRole.get("id"));
        }

        var message = samlConfig.get("scimEnabled") ? "enabled" : "disabled";

        samlConfig.save().then(function () {
          _ember["default"].get(_this5, "flashMessages").success("SCIM Provisioning is " + message);
          _this5.refresh();
        })["catch"](function (e) {
          var message = e.message || "There was an error enabling auto provisioning.";
          _ember["default"].get(_this5, "flashMessages").danger(message);
        })["finally"](function () {
          _this5.controller.set("scimSettingsSaving", false);
        });
      },

      addToWhitelist: function addToWhitelist(user) {
        var _this6 = this;

        var org = _ember["default"].get(this.currentModel, "organization");
        var wlm = this.get("store").createRecord("whitelistMembership", {
          user: user,
          organization: org
        });
        var name = user.get("name");

        wlm.save().then(function () {
          _ember["default"].get(_this6, "flashMessages").success(name + " added to whitelist");
          _this6.controller.set("selectedUser", "");
          _this6.refresh();
        })["catch"](function (e) {
          var message = e.message || "There was an error adding " + name + " to whitelist";
          _ember["default"].get(_this6, "flashMessages").danger(message);
        });
      },

      removeFromWhitelist: function removeFromWhitelist(user) {
        var _this7 = this;

        var whitelist = this.get("store").peekAll("whitelistMembership");
        var wlm = whitelist.find(function (wlm) {
          var userMatch = wlm.get("links.user") == user.get("links.self");
          var orgMatch = wlm.get("links.organization") == _ember["default"].get(_this7.currentModel, "organization.links.self");
          return userMatch && orgMatch;
        });
        var name = user.get("name");

        wlm.destroyRecord().then(function () {
          _ember["default"].get(_this7, "flashMessages").success(name + " removed from whitelist");
          _this7.refresh();
        })["catch"](function (e) {
          var message = e.message || "There was an error removing " + name + " from whitelist";
          _ember["default"].get(_this7, "flashMessages").danger(message);
        });
      }
    }
  });
});