define('diesel/components/link-to-aptible/component', ['exports', 'ember', 'diesel/config/environment'], function (exports, _ember, _dieselConfigEnvironment) {
  exports['default'] = _ember['default'].Component.extend({
    attributeBindings: ['href', 'target'],
    tagName: 'a',
    app: 'dashboard',
    path: null,
    href: _ember['default'].computed('path', 'app', function () {
      var path = this.get('path');
      var app = this.get('app');
      var host = _dieselConfigEnvironment['default'].aptibleHosts[app];

      _ember['default'].assert('The app "' + app + '" is not a valid argument to link-to-aptible', !!host);
      if (!path) {
        return host;
      }
      return [host, path].join('/');
    })
  });
});