define('diesel/no-account/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    renderTemplate: function renderTemplate() {
      this._super.apply(this, arguments);
      this.render('sidebars/organizations-accounts', {
        into: 'enclave-wrapper',
        outlet: 'sidebar'
      });
    }
  });
});