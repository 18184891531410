define('diesel/components/modal-create-log-drain-v2/component', ['exports', 'ember', 'diesel/mixins/components/modal-base'], function (exports, _ember, _dieselMixinsComponentsModalBase) {

  function displayField(fieldName) {
    var presetPath = 'drainPreset.fields.' + fieldName;

    return _ember['default'].computed(presetPath, 'drainPreset.disable.[]', function () {
      var disabledFields = this.get('drainPreset.disable');
      if (disabledFields && disabledFields.includes(fieldName)) {
        return false;
      }
      return !this.get(presetPath);
    });
  }

  function fieldTip(fieldName) {
    var presetTipPath = 'drainPreset.tips.' + fieldName;
    var defaultTipPath = 'tips.' + fieldName;

    return _ember['default'].computed(presetTipPath, defaultTipPath, function () {
      return this.get(presetTipPath) || this.get(defaultTipPath);
    });
  }

  exports['default'] = _ember['default'].Component.extend(_dieselMixinsComponentsModalBase['default'], {
    newLogDrainV2: null, // set when opening modal

    tips: {
      loggingToken: 'All log lines sent through this Log Drain will be prefixed with this token',
      url: 'Must be HTTPS'
    },

    drainPreset: null,
    needsPreset: _ember['default'].computed.none('drainPreset'),

    needsDrainType: displayField('drainType'),
    needsDrainHost: displayField('drainHost'),
    needsDrainPort: displayField('drainPort'),
    needsLoggingToken: displayField('loggingToken'),

    drainTypeTip: fieldTip('drainType'),
    drainHostTip: fieldTip('drainHost'),
    drainPortTip: fieldTip('drainPort'),
    loggingTokenTip: fieldTip('loggingToken'),
    urlTip: fieldTip('url'),

    title: _ember['default'].computed('account.handle', function () {
      return 'Create a new log drain on ' + this.get('account.handle');
    }),

    description: _ember['default'].computed('drainPreset.label', 'account.handle', function () {
      if (this.get('needsPreset')) {
        return 'Log Drains let you collect stdout and stderr logs from your apps\n              and databases deployed in the ' + this.get('account.handle') + '\n              environment and route them to a log destination.';
      }

      var label = this.get('drainPreset.label');
      if (label) {
        return 'Finish configuring your ' + label + ' Log Drain.';
      }

      return 'Configure your Log Drain';
    }),

    isPending: _ember['default'].computed.or('newLogDrainV2.isSaving', 'newLogDrainV2.isValidating'),
    hasError: _ember['default'].computed.gt('errors.newLogDrainV2.handle.length', 0),

    disableSave: _ember['default'].computed('newLogDrainV2.isElasticsearch', 'esDatabases', function () {
      if (this.get('newLogDrainV2.isElasticsearch') && this.get('esDatabases.length') === 0) {
        return true;
      }
      return this.get('newLogDrainV2.isSaving');
    }),

    applyPreset: function applyPreset(preset) {
      this.get('newLogDrainV2').setProperties(preset.fields);
      this.set('drainPreset', preset);
    },

    actions: {
      beforeDismiss: function beforeDismiss() {
        this.get('newLogDrainV2').rollbackAttributes();
      },

      createLogDrain: function createLogDrain() {
        var logDrain = this.get('newLogDrainV2');
        this.sendAction('onCreateLogDrain', logDrain);
      },

      revertPreset: function revertPreset() {
        var _getProperties = this.getProperties('account', 'newLogDrainV2');

        var account = _getProperties.account;
        var newLogDrainV2 = _getProperties.newLogDrainV2;

        Object.keys(newLogDrainV2.changedAttributes()).forEach(function (k) {
          if (k === 'account') {
            return;
          }
          newLogDrainV2.set(k);
        });

        newLogDrainV2.setProperties(account.defaultLogDrainAttributes());

        this.set('drainPreset', null);
      },

      presetLogentries: function presetLogentries() {
        this.applyPreset({
          label: 'Logentries',
          fields: {
            drainType: 'syslog_tls_tcp',
            drainHost: 'api.logentries.com',
            drainPort: 20000
          },
          tips: {
            loggingToken: 'Add a new Token TCP Log in Logentries,\n                         then copy the Logging Token provided by\n                         Logentries.'
          },
          disable: []
        });
      },

      presetLogdna: function presetLogdna() {
        this.applyPreset({
          label: 'LogDNA',
          fields: {
            drainType: 'https_post'
          },
          tips: {
            url: 'Must be in the format of \n                 "https://logs.logdna.com/aptible/ingest/&lt;INGESTION KEY&gt;".\n                 Refer to <a href="https://docs.logdna.com/docs/aptible-logs">\n                 https://docs.logdna.com/docs/aptible-logs</a> for more\n                 options.'
          },
          disable: []
        });
      },

      presetPapertrail: function presetPapertrail() {
        this.applyPreset({
          label: 'Papertrail',
          fields: { drainType: 'syslog_tls_tcp' },
          tips: {
            drainHost: 'Add a new Log Destination in Papertrail\n                      (make sure to accept TCP + TLS connections\n                      and logs from unrecognized senders),\n                      then copy the host from the Log Destination.',
            drainPort: 'Add the port from the Log Destination you added.'
          },
          disable: ['loggingToken']
        });
      },

      presetElasticsearch: function presetElasticsearch() {
        this.applyPreset({
          label: 'Elasticsearch',
          fields: { drainType: 'elasticsearch_database' },
          disable: []
        });
      },

      presetSumologic: function presetSumologic() {
        this.applyPreset({
          label: 'Sumologic',
          fields: { drainType: 'https_post' },
          tips: {
            url: 'Create a new Hosted Collector in Sumologic using a HTTP source,\n                then copy the HTTP Source Address.'
          },
          disable: []
        });
      },

      presetManualConfiguration: function presetManualConfiguration() {
        this.applyPreset({
          label: null,
          fields: {},
          disable: []
        });
      }
    }
  });
});