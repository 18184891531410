define("diesel/organization/single-sign-on/controller", ["exports", "ember", "diesel/config/environment"], function (exports, _ember, _dieselConfigEnvironment) {
  exports["default"] = _ember["default"].Controller.extend({
    ssoUrlBase: _dieselConfigEnvironment["default"].authBaseUri + "/organizations/",
    isRemoving: false,
    canRemove: _ember["default"].computed.not("model.organization.ssoEnforced"),
    enforceIsSaving: false,
    autoProvisionSaving: false,
    autoProvisionDisabled: _ember["default"].computed.or("autoProvisionSaving", "model.samlConfig.scimEnabled"),
    selectedUser: null,
    scimProvisioningUrl: _dieselConfigEnvironment["default"].authBaseUri + "/scim/v2",
    scimToken: null,
    generatingScimToken: false,
    displayScimToken: _ember["default"].computed.or("scimToken", "model.samlConfig.scimEnabled"),
    scimDisabled: _ember["default"].computed.not("model.samlConfig.scimEnabled"),
    canEnableScim: _ember["default"].computed.and("scimToken", "scimDisabled")
  });
});