define('diesel/utils/authorization-context', ['exports', 'ember'], function (exports, _ember) {

  // This class is meant to bootstrap all models required to render dashboard
  // for a given organizations' users.  It also provides a context to be used
  // for difficult computations based on the current user and their relationship
  // to their organization, e.g. permission checks

  // AuthorizationContext should be instantiated with the following

  // * organization
  // * currentUserRoles
  // * accounts (all accounts for given user, this service will filter)
  // * currentUser

  exports['default'] = _ember['default'].Object.extend({
    load: function load() {
      var _this = this;

      // Expect this to be initialized with a store.

      var _getProperties = this.getProperties('organization');

      var organization = _getProperties.organization;

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        _ember['default'].RSVP.hash({
          users: organization.get('users'),
          invitations: organization.get('invitations'),
          roles: organization.get('roles')
        }).then(function (props) {

          props.stacks = [];
          props.eligibleDevelopmentStacks = [];
          props.eligibleProductionStacks = [];

          // All basic relationships are loaded, store those and load permissions
          _this.setProperties(props);
          return _ember['default'].RSVP.all([]);
        }).then(function (permissionsByAccount) {
          var permissions = [];

          permissionsByAccount.forEach(function (accountPermissions) {
            permissions = permissions.concat(accountPermissions);
          });

          _this.set('permissions', permissions);
          resolve(_this);
        })['catch'](function (e) {
          return reject(e);
        });
      });
    },

    editableRoles: _ember['default'].computed('roles.@each.type', 'organizationHasEnclaveProduct', function () {
      var roles = this.get('roles');

      if (!this.get('organizationHasEnclaveProduct')) {
        roles = roles.rejectBy('isPlatformOnly');
      }

      return roles;
    }),

    // Computed related to organization's roles
    organizationOwnerRoles: _ember['default'].computed.filterBy('roles', 'type', 'owner'),
    organizationNonOwnerRoles: _ember['default'].computed.filter('roles', function (role) {
      return role.get('type') !== 'owner';
    }),
    organizationPlatformUserRoles: _ember['default'].computed.filterBy('roles', 'type', 'platform_user'),
    organizationPlatformAdminRoles: _ember['default'].computed.filterBy('roles', 'type', 'platform_owner'),
    organizationComplianceUserRoles: _ember['default'].computed.filter('roles', function (role) {
      return role.get('type') === "compliance_user" || role.get('type') === "compliance_rooms_collaborator" || role.get('type') === "configurable_read_only";
    }),
    organizationComplianceAdminRoles: _ember['default'].computed.filterBy('roles', 'type', 'compliance_owner'),
    organizationComplianceRoles: _ember['default'].computed.union('organizationComplianceAdminRoles', 'organizationComplianceUserRoles'),
    organizationHasGridironProduct: _ember['default'].computed.alias('enabledFeatures.gridiron'),

    // TODO: This will have to be a plan match once Enclave is separate from gridiron
    organizationHasEnclaveProduct: _ember['default'].computed.gt('accounts.length', 0),

    // Computeds related to user's roles in organization
    hasVerifiedEmail: _ember['default'].computed.reads('currentUser.verified'),
    userPlatformUserRoles: _ember['default'].computed.filterBy('currentUserRoles', 'type', 'platform_user'),
    userPlatformAdminRoles: _ember['default'].computed.filterBy('currentUserRoles', 'type', 'platform_owner'),
    userGridironUserRoles: _ember['default'].computed.filterBy('currentUserRoles', 'type', 'compliance_user'),
    userGridironAdminRoles: _ember['default'].computed.filterBy('currentUserRoles', 'type', 'compliance_owner'),
    userOrganizationAdminRoles: _ember['default'].computed.filter('currentUserRoles', function (role) {
      return role.get('type') === 'owner' || role.get('type') === 'compliance_owner';
    }),
    userIsEnclaveUser: _ember['default'].computed.gt('userPlatformUserRoles.length', 0),
    userIsEnclaveAdmin: _ember['default'].computed.gt('userPlatformAdminRoles.length', 0),
    userIsGridironAdmin: _ember['default'].computed.gt('userGridironAdminRoles.length', 0),
    userIsOrganizationAdmin: _ember['default'].computed.gt('userOrganizationAdminRoles.length', 0),

    userCanCreateNewRoles: _ember['default'].computed.or('userIsOrganizationAdmin', 'userIsGridironAdmin', 'userIsEnclaveAdmin'),

    userIsEnclaveOrOrganizationAdmin: _ember['default'].computed.or('userIsEnclaveAdmin', 'userIsOrganizationAdmin'),
    userIsGridironOrOrganizationAdmin: _ember['default'].computed.or('userIsGridironAdmin', 'userIsOrganizationAdmin'),
    userHasEnclaveAccess: _ember['default'].computed.or('userIsEnclaveAdmin', 'userIsEnclaveUser', 'userIsOrganizationAdmin'),

    doesUserHavePrivilegedMembershipForRole: function doesUserHavePrivilegedMembershipForRole(role) {
      // This method is dumb and counts on memberhsips being loaded on the role
      // FIXME: Load memberships from users and cache them in this context
      var privilegedMemberships = role.get('memberships').filterBy('privileged', true);
      return !!privilegedMemberships.findBy('user.id', this.get('currentUser.id'));
    },

    hasAccountScope: function hasAccountScope(scope, account) {
      // Scopes: read, manage
      if (scope === 'manage' && !this.get('hasVerifiedEmail')) {
        return false;
      }

      return this.get('currentUserRoles').any(function (role) {
        return account.permitsRole(role, scope);
      });
    },

    hasOrganizationScope: function hasOrganizationScope(scope) {
      // Scopes: read, manage
      var supportedScopes = ['read', 'manage', 'enclave/manage'];
      _ember['default'].assert('hasOrganizationScope supports: ' + supportedScopes + ' (passed: ' + scope + ')', supportedScopes.includes(scope));

      if (scope === 'read') {
        return true;
      }

      if (scope === 'enclave/manage') {
        if (!this.get('hasVerifiedEmail')) {
          return false;
        }

        return this.get('userIsEnclaveOrOrganizationAdmin');
      }

      return this.get('userIsOrganizationAdmin');
    },

    hasGridironScope: function hasGridironScope(scope) {
      // Scopes: read, manage
      // Eventually will have scope for each gridiron product
      if (scope === 'read') {
        return true;
      }

      if (!this.get('hasVerifiedEmail')) {
        return false;
      }

      if (this.get('userIsGridironOrOrganizationAdmin')) {
        // Admin has all scopes
        return true;
      }

      return false;
    },

    hasRoleScope: function hasRoleScope(scope, role) {
      // Scopes: read, manage, invite
      if (scope === 'read') {
        return true;
      }

      if (!this.get('hasVerifiedEmail')) {
        return false;
      }

      if (this.get('userIsOrganizationAdmin')) {
        return true;
      }

      if (role.get('isPlatform') && this.get('userIsEnclaveAdmin')) {
        return true;
      }

      if (role.get('isComplianceRole') && this.get('userIsGridironAdmin')) {
        return true;
      }

      if (scope === 'invite' && this.doesUserHavePrivilegedMembershipForRole(role)) {
        return true;
      }

      return false;
    }
  });
});