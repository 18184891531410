define("diesel/components/sso-id-edit/component", ["exports", "ember", "diesel/config/environment"], function (exports, _ember, _dieselConfigEnvironment) {
  exports["default"] = _ember["default"].Component.extend({
    flashMessages: _ember["default"].inject.service(),

    // Required parameters
    samlConfiguration: null,
    organization: null,

    // Internal state
    ssoUrlBase: _dieselConfigEnvironment["default"].accountBaseUri + "/sso/",
    handleIsSaving: false,
    currentHandle: _ember["default"].computed('samlConfiguration', 'organization', function () {
      var handle = this.get('samlConfiguration.handle');
      return handle ? handle : this.get('organization.id');
    }),
    possibleHandle: _ember["default"].computed.oneWay('currentHandle'),

    handleNotChanged: _ember["default"].computed('currentHandle', 'possibleHandle', function () {
      return this.get('currentHandle') == this.get('possibleHandle');
    }),
    disableSubmit: _ember["default"].computed.or('handleNotChanged', 'handleIsSaving'),

    actions: {
      changeHandle: function changeHandle() {
        var _this = this;

        this.set("handleIsSaving", true);

        var saml = this.get("samlConfiguration");
        saml.handle = this.get("possibleHandle");

        return saml.save().then(function () {
          _this.get('flashMessages').success('SSO Login ID updated');
          _this.set('currentHandle', saml.handle);
        })["catch"](function (e) {
          _this.get('flashMessages').danger("SSO Login ID could not be updated: " + e.message);
        })["finally"](function () {
          _this.set("handleIsSaving", false);
        });
      }
    }
  });
});