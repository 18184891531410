define('diesel/organization/roles/index/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      return this.modelFor('organization');
    },

    redirect: function redirect(model) {
      this.transitionTo('organization.roles.type', 'compliance');
    }
  });
});