define('diesel/components/estimated-cost/component', ['exports', 'ember'], function (exports, _ember) {

  var hoursPerMonth = 731;
  var centsToDollars = function centsToDollars(cents) {
    return '$' + (cents / 100).toFixed(2);
  };

  exports['default'] = _ember['default'].Component.extend({
    rateInDollars: _ember['default'].computed('account.appContainerCentsPerHour', function () {
      return centsToDollars(this.get('account.appContainerCentsPerHour'));
    }),

    unitOfMeasure: _ember['default'].computed('account.type', 'count', function () {
      if (!this.get('account.type')) {
        return '';
      }

      var p = this.get('count') === 1 ? '' : 's';
      return this.get('account.type').capitalize() + ' App Container' + p;
    }),

    total: _ember['default'].computed('account.appContainerCentsPerHour', 'count', 'size', function () {
      return centsToDollars(hoursPerMonth * (this.get('count') * (this.get('size') / 1024)) * this.get('account.appContainerCentsPerHour'));
    })
  });
});