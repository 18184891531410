define('diesel/organization/roles/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    modalDisplay: _ember['default'].inject.service(),

    model: function model() {
      return this.modelFor('organization');
    },

    setupController: function setupController(controller, authorizationContext) {
      controller.setProperties({ authorizationContext: authorizationContext });
    },

    actions: {
      openCreateRoleModal: function openCreateRoleModal() {
        var authorizationContext = this.modelFor('organization');
        var organization = authorizationContext.organization;

        var type = 'compliance_user';
        var newRole = this.store.createRecord('role', { organization: organization, type: type });

        var modalDisplay = this.get('modalDisplay');
        modalDisplay.showModal('modal-create-role', { newRole: newRole, authorizationContext: authorizationContext }, {
          actions: {
            onCreateRole: modalDisplay.modalAction(this, 'onCreateRole')
          }
        });
      },

      onCreateRole: function onCreateRole(role) {
        var message = role.get('name') + ' created';
        _ember['default'].get(this, 'flashMessages').success(message);
        return this.transitionTo('role', role);
      }
    }
  });
});