define('diesel/components/handle-input/component', ['exports', 'diesel/components/one-way-input/component', 'diesel/mixins/views/autofocusable'], function (exports, _dieselComponentsOneWayInputComponent, _dieselMixinsViewsAutofocusable) {
  var maxChars = 64;

  exports.maxChars = maxChars;
  function replaceWithLower(match) {
    return match.toLowerCase();
  }

  function truncate(input) {
    return input.slice(0, maxChars);
  }

  var capitalLetters = /[A-Z]/g;
  var spaces = /\s+/g;
  var nonAlphaNumerics = /[^0-9a-z._-]/g;

  exports['default'] = _dieselComponentsOneWayInputComponent['default'].extend(_dieselMixinsViewsAutofocusable['default'], {
    sanitizeInput: function sanitizeInput(input) {
      if (typeof input !== 'string') {
        return input;
      }

      return truncate(input, maxChars).replace(capitalLetters, replaceWithLower).replace(spaces, '-').
      // FIXME: nonAlphaNumerics really should be replaced with an empty string,
      // however that breaks the `value` attr binding so that the first invalid
      // character is never actually stripped.
      replace(nonAlphaNumerics, '');
    }
  });
});