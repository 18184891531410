define('diesel/requires-authorization/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    redirect: function redirect() {
      if (this.get('authorization.hasNoOrganizations')) {
        return this.transitionTo('no-organization');
      }
    },

    model: function model() {
      return this.get('authorization').load();
    }
  });
});