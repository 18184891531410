define("diesel/mixins/routes/service-metrics", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Mixin.create({
    model: function model(params) {
      return _ember["default"].Object.create({
        resource: this.getResource(params),
        service: this.getService(params),
        uiState: this.defaultUiState()
      });
    },

    defaultUiState: function defaultUiState() {
      return _ember["default"].Object.create({
        dataHorizon: "1h",
        lastReload: Date.now()
      });
    }
  });
});