define('diesel/models/account', ['exports', 'ember', 'ember-data', 'diesel/models/base'], function (exports, _ember, _emberData, _dieselModelsBase) {

  function getRoleIdFromPermission(permission) {
    return permission.belongsTo('role').id();
  }

  exports['default'] = _dieselModelsBase['default'].extend({
    // properties
    name: _emberData['default'].attr('string'),
    handle: _emberData['default'].attr('string'),
    number: _emberData['default'].attr('string'),
    type: _emberData['default'].attr('string'),
    syslogHost: _emberData['default'].attr('string'),
    syslogPort: _emberData['default'].attr('string'),
    organizationUrl: _emberData['default'].attr('string'),
    sweetnessStack: _emberData['default'].attr('string'),
    sweetnessStackVersion: _emberData['default'].attr('string'),
    activated: _emberData['default'].attr('boolean'),
    containerCount: _emberData['default'].attr('number'),
    appContainerCount: _emberData['default'].attr('number'),
    databaseContainerCount: _emberData['default'].attr('number'),
    domainCount: _emberData['default'].attr('number'),
    totalAppCount: _emberData['default'].attr('number'),
    totalDatabaseCount: _emberData['default'].attr('number'),
    totalDiskSize: _emberData['default'].attr('number'),
    totalBackupSize: _emberData['default'].attr('number'),

    // relationships
    apps: _emberData['default'].hasMany('app', { async: true }),
    certificates: _emberData['default'].hasMany('certificate', { async: true }),
    databases: _emberData['default'].hasMany('database', { async: true }),
    permissions: _emberData['default'].hasMany('permission', {
      async: false
    }),
    organization: _emberData['default'].belongsTo('organization', { async: true }),
    logDrains: _emberData['default'].hasMany('log-drain', { async: false }),
    metricDrains: _emberData['default'].hasMany('metric-drain', { async: true }),
    vhosts: _emberData['default'].hasMany('vhost', { async: true }),
    stack: _emberData['default'].belongsTo('stack', { async: true }),
    services: _emberData['default'].hasMany('service', { async: true }),
    activityReports: _emberData['default'].hasMany('activity-report', { async: true }),
    backupRetentionPolicies: _emberData['default'].hasMany('backup-retention-policy', { async: true }),
    backups: _emberData['default'].hasMany('backup', { async: true }),

    // computed properties
    allowPHI: _ember['default'].computed.match('type', /production/),
    appContainerCentsPerHour: 8,
    pending: _ember['default'].computed.not('activated'),
    persistedVhosts: _ember['default'].computed.filterBy('vhosts', 'isNew', false),
    vhostNames: _ember['default'].computed.mapBy('persistedVhosts', 'virtualDomain'),
    persistedLogDrains: _ember['default'].computed.filterBy('logDrains', 'isNew', false),
    logDrainNames: _ember['default'].computed.mapBy('persistedLogDrains', 'handle'),
    appUsage: _ember['default'].computed.mapBy('apps', 'usage'),
    databaseUsage: _ember['default'].computed.mapBy('databases', 'usage'),
    containerUsage: _ember['default'].computed.sum('appUsage', 'databaseUsage'),
    isV1: _ember['default'].computed.equal('sweetnessStackVersion', 'v1'),
    isV2: _ember['default'].computed.equal('sweetnessStackVersion', 'v2'),
    hasApps: _ember['default'].computed.gt('apps.length', 0),
    hasDatabases: _ember['default'].computed.gt('databases.length', 0),
    hasLogDrains: _ember['default'].computed.gt('logDrains.length', 0),
    hasMetricDrains: _ember['default'].computed.gt('metricDrains.length', 0),
    hasBackupRetentionPolicies: _ember['default'].computed.gt('backupRetentionPolicies.length', 0),
    hasBackups: _ember['default'].computed.gt('backups.length', 0),
    hasResources: _ember['default'].computed.or('hasApps', 'hasDatabases', 'hasLogDrains', 'hasMetricDrains', 'hasBackups'),

    getUsageByResourceType: function getUsageByResourceType(type) {
      var usageAttr = ({ container: 'containerCount', disk: 'totalDiskSize',
        domain: 'domainCount', backup: 'totalBackupSize' })[type];
      return this.get(usageAttr);
    },

    scopesForRole: function scopesForRole(role) {
      var permissions = undefined;

      if (role.get('isOwner') && role.get('links.organization') === this.get('links.organization')) {
        return new _ember['default'].RSVP.Promise(function (resolve) {
          resolve(true);
        });
      }

      return this.get('permissions').any(function (_permissions) {
        permissions = _permissions;

        return permissions.map(function (perm) {
          return {
            roleId: getRoleIdFromPermission(perm),
            scope: perm.get('scope')
          };
        });
      }).then(function (accountRoleScopes) {
        return _ember['default'].A(_ember['default'].A(accountRoleScopes).filter(function (accountRoleScope) {
          return role.get('id') === accountRoleScope.roleId;
        })).mapBy('scope');
      });
    },

    // Checking a specific scope
    hasRoleScope: function hasRoleScope(role, scope) {
      return this.get('permissions').any(function (permission) {
        return getRoleIdFromPermission(permission) === role.get('id') && permission.get('scope') === scope;
      });
    },

    findPermission: function findPermission(role, scope) {
      return this.get('permissions').find(function (permission) {
        return getRoleIdFromPermission(permission) === role.get('id') && permission.get('scope') === scope;
      });
    },

    // Checking a level of scope permission.
    permitsRole: function permitsRole(role, scope) {
      if ((role.get('isPlatformOwner') || role.get('isAccountOwner')) && role.get('links.organization') === this.get('links.organization')) {
        return true;
      }

      return this.get('permissions').any(function (permission) {
        return getRoleIdFromPermission(permission) === role.get('id') && permission.can(scope);
      });
    },

    createLogDrain: function createLogDrain() {
      var attrs = this.defaultLogDrainAttributes();
      attrs.account = this;
      return this.store.createRecord('log-drain', attrs);
    },

    defaultLogDrainAttributes: function defaultLogDrainAttributes() {
      return {
        drainDatabases: this.get('isV2'),
        drainEphemeralSessions: this.get('isV2'),
        drainApps: true
      };
    }
  });
});