define('diesel/components/permissions-by-account/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    scopes: _ember['default'].computed('role.type', function () {
      var roleId = this.get('role').get('id');
      var scopes = [];
      this.get('account').get('permissions').forEach(function (permission) {
        if (permission.get('links.role').split('/').pop() === roleId) {
          scopes.push(permission.get('scope'));
        }
      });
      return scopes.join(', ');
    })
  });
});