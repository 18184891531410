define('diesel/components/ultimate-select/component', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;
  exports['default'] = _ember['default'].Component.extend({
    autofocus: false,
    disabled: false,
    name: null,
    selectClass: '',
    selected: null,
    selectedKey: null,
    itemKey: null,
    itemValue: null,

    items: _ember['default'].computed(function () {
      return _ember['default'].A([]);
    }),

    /**
     * @return [{key, value}]
     */
    keyValues: _ember['default'].computed('items.[]', 'itemKey', 'itemValue', function () {
      var _getProperties = this.getProperties('items', 'itemKey', 'itemValue');

      var items = _getProperties.items;
      var itemKey = _getProperties.itemKey;
      var itemValue = _getProperties.itemValue;

      return items.map(function (item) {
        var key = itemKey ? get(item, itemKey) : item;
        var value = itemValue ? get(item, itemValue) : item;
        return { key: key, value: value };
      });
    }),

    // returns the selectedIndex if there is a selection, else -1
    selectedIndex: _ember['default'].computed('items.[]', 'selectedValues.[]', 'selected', 'selectedKey', 'itemKey', function () {
      var _getProperties2 = this.getProperties('selected', 'selectedKey', 'itemKey');

      var selected = _getProperties2.selected;
      var selectedKey = _getProperties2.selectedKey;
      var itemKey = _getProperties2.itemKey;

      _ember['default'].assert('Only one of `selected` or `selectedKey` may be used in {{ultimate-select}}', !(selected && selectedKey));

      var haystack = [];
      var needle = undefined;

      if (selectedKey) {
        haystack = this.get('keyValues').mapBy('key');
        needle = selectedKey;
      } else if (selected) {
        haystack = this.get('items');
        needle = selected;

        if (itemKey) {
          haystack = haystack.mapBy(itemKey);
          needle = get(selected, itemKey);
        }
      }

      return haystack.indexOf(needle);
    }),

    didInsertElement: function didInsertElement() {
      if (this.get('autofocus')) {
        this.$('select').focus();
      }
    },

    actions: {
      update: function update() {
        var initialValue = this.$('select')[0];
        var selectedIndex = initialValue.selectedIndex;
        var items = _ember['default'].A(this.get('items'));
        var promptOffset = this.get('prompt') ? 1 : 0;
        var itemKey = this.get('itemKey');
        var selected = items.objectAt(selectedIndex - promptOffset);

        if (selected) {
          if (this.get('selectByKey') || this.get('selectedKey')) {
            // If providing a key, you likely want the key value returned and not
            // the entire item
            selected = get(selected, itemKey);
          }

          this.attrs.update(selected);
        }
      }
    }
  });
});