define('diesel/verify/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model(params) {
      var _this = this;

      var options = {
        challengeId: params.challenge_id,
        verificationCode: params.verification_code,
        type: 'email_verification_challenge'
      };

      var verification = this.store.createRecord('verification', options);

      return verification.save().then(function () {
        var user = _this.session.get('currentUser');

        // this will update the 'verification' property
        return user.reload();
      });
    }
  });
});