define('diesel/models/stack', ['exports', 'ember', 'ember-data', 'diesel/models/base'], function (exports, _ember, _emberData, _dieselModelsBase) {
  exports['default'] = _dieselModelsBase['default'].extend({
    name: _emberData['default'].attr('string'),
    region: _emberData['default'].attr('string'),
    version: _emberData['default'].attr('string'),

    outboundIpAddresses: _emberData['default'].attr(),

    memoryLimits: _emberData['default'].attr('boolean'),
    cpuLimits: _emberData['default'].attr('boolean'),
    intrusionDetection: _emberData['default'].attr('boolean'),
    exposeIntrusionDetectionReports: _emberData['default'].attr('boolean'),

    organization: _emberData['default'].belongsTo('organization', { async: true }),
    accounts: _emberData['default'].hasMany('account', { async: true }),
    intrusionDetectionReports: _emberData['default'].hasMany('intrusion-detection-report', { async: true }),
    vpnTunnels: _emberData['default'].hasMany('vpn-tunnel', { async: true }),
    vpcPeers: _emberData['default'].hasMany('vpc-peer', { async: true }),

    isV1: _ember['default'].computed.equal('version', 'v1'),
    isV2: _ember['default'].computed.equal('version', 'v2'),

    isEligibleForNewAccounts: _ember['default'].computed.alias('isV2'),

    // TODO: Can we get rid of these computeds? They're probably not safe, since
    // we're looking at a relation... Ideally, we'd want to update them once we
    // have access to the relationships API.
    isDedicated: _ember['default'].computed('organization.id', function () {
      return !!this.get('organization.id');
    }),

    isShared: _ember['default'].computed.not('isDedicated'),

    hasAccounts: _ember['default'].computed.gt('accounts.length', 0),

    displayOutboundIpAddresses: _ember['default'].computed('outboundIpAddresses', function () {
      var addrs = this.get('outboundIpAddresses');

      if (!addrs) {
        return;
      }

      return addrs.join(', ');
    }),

    displayDetail: _ember['default'].computed('name', 'region', 'isDedicated', function () {
      return [this.get('name'), this.get('region'), this.get('isDedicated') ? 'Dedicated Tenancy' : 'Shared Tenancy'].join(' - ');
    })
  });
});