define('diesel/components/edit-role-panel/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    store: _ember['default'].inject.service(),
    flashMessages: _ember['default'].inject.service(),
    modalDisplay: _ember['default'].inject.service(),

    classNames: ['workforce-role'],
    role: null,
    invitedUser: null,
    isSaving: false, // Set this to disable controls
    shortName: _ember['default'].computed.reads('organizationProfile.shortName'),
    name: _ember['default'].computed('shortName', 'role.name', function () {
      if (!this.get('role.name')) {
        return '';
      }

      var name = this.get('organizationProfile.name');
      return this.get('role.name').replace('{{shortName}}', name);
    }),

    description: _ember['default'].computed('shortName', 'role.description', function () {
      if (!this.get('role.description')) {
        return '';
      }

      var name = this.get('shortName');
      return this.get('role.description').replace('{{shortName}}', name);
    }),

    sortedMemberships: _ember['default'].computed('role.memberships.[]', function () {
      return this.get('role.memberships').sortBy('user.name');
    }),

    roleInvitations: _ember['default'].computed('invitations.[]', function () {
      if (this.get('role.isNew')) {
        return [];
      }
      return this.get('invitations').filterBy('role.id', this.get('role.id'));
    }),

    nonMembers: _ember['default'].computed('role.users.[]', 'role.organization.users.[]', function () {
      var members = this.get('role.users');
      var organizationUsers = this.get('role.organization.users');

      return organizationUsers.filter(function (user) {
        return !members.includes(user);
      }).sortBy('name');
    }),

    disableAdd: _ember['default'].computed('invitedUser', 'isSaving', function () {
      return !this.get('invitedUser') || this.get('isSaving');
    }),

    _isProtectedMembership: function _isProtectedMembership(membership) {
      return !!membership.get('protectedDesignationLabel');
    },

    actions: {
      addMember: function addMember(user) {
        var _this = this;

        var role = this.get('role');
        var userUrl = user.get('url');
        var organizationProfile = this.get('organizationProfile');
        var membership = this.get('store').createRecord('membership', {
          role: role, user: user, userUrl: userUrl
        });

        this.set('isSaving', true);

        membership.save().then(function () {
          role.get('users').pushObject(user);
          _this.set('isSaving', false);
          _this.set('invitedUser', null);
          organizationProfile.observeDocumentBuilds();
        });
      },

      removeMember: function removeMember(membership) {
        var _this2 = this;

        if (this._isProtectedMembership(membership)) {
          return;
        }

        var role = this.get('role');
        var userName = membership.get('user.name');
        var roleName = role.get('name');
        var organizationProfile = this.get('organizationProfile');
        var confirmMsg = '\nAre you sure you want to remove ' + userName + ' from ' + roleName + '?\n';

        if (!window.confirm(confirmMsg)) {
          return false;
        }

        membership.destroyRecord().then(function () {
          role.get('users').removeObject(membership.get('user'));
          organizationProfile.observeDocumentBuilds();
        })['catch'](function (e) {
          _this2.get('flashMessages').danger('There was an error: ' + e.message);
        });
      },

      showInviteModal: function showInviteModal() {
        var role = this.get('role');

        var modalDisplay = this.get('modalDisplay');
        modalDisplay.showModal('modal-invite-team-to-role', { role: role }, {
          actions: {
            inviteTeam: modalDisplay.modalAction(this, 'inviteTeam')
          }
        });
      },

      inviteTeam: function inviteTeam(validEmails) {
        var _this3 = this;

        if (validEmails.length === 0) {
          return;
        }

        var _getProperties = this.getProperties('role', 'store', 'organization');

        var role = _getProperties.role;
        var store = _getProperties.store;
        var organization = _getProperties.organization;

        return role.save().then(function () {
          var promises = validEmails.map(function (email) {
            var inviteParams = { organization: organization, role: role, email: email };
            var invite = store.createRecord('invitation', inviteParams);
            return invite.save()['catch'](function (e) {
              // Do not show the unsaved invite in the invitations list
              organization.get('invitations').removeObject(invite);
              throw e;
            });
          });

          return _ember['default'].RSVP.all(promises);
        }).then(function (invitations) {
          var plural = invitations.length === 1 ? 'invitation' : 'invitations';
          _this3.get('flashMessages').success('Sent ' + invitations.length + ' ' + plural + '.');
        })['catch'](function (e) {
          _this3.get('flashMessages').danger('There was an error: ' + e.message);
          return e; // keeps modal from closing
        });
      },

      deleteInvitation: function deleteInvitation(invitation) {
        var _this4 = this;

        var email = invitation.get('email');
        var confirmMsg = '\nAre you sure you want to delete the invitation to ' + email + '?\n';
        if (!window.confirm(confirmMsg)) {
          return false;
        }

        invitation.destroyRecord().then(function () {
          var message = 'The invitation to ' + invitation.get('email') + ' has been removed.';
          _this4.get('flashMessages').success(message);
        })['catch'](function (e) {
          var message = 'An error occurred. Please retry removing the inviation.';
          message = e.message || message;
          _this4.get('flashMessages').danger(message);
        });
      },

      resendInvitation: function resendInvitation(invitation) {
        var _this5 = this;

        var reset = this.get('store').createRecord('reset', {
          type: 'invitation',
          invitationId: invitation.get('id')
        });

        reset.save().then(function () {
          var message = 'Invitation resent to ' + invitation.get('email');
          _ember['default'].get(_this5, 'flashMessages').success(message);
        })['catch'](function (e) {
          var message = 'An error occurred. Please try resending the invitation again.';
          message = e.message || message;
          _ember['default'].get(_this5, 'flashMessages').danger(message);
        });
      }
    }
  });
});