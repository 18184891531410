define('diesel/settings/requires-elevation/admin/controller', ['exports', 'ember'], function (exports, _ember) {

  var OTP_STATE_DISABLED = 1;
  var OTP_STATE_PENDING = 2;
  var OTP_STATE_ENABLED = 3;

  exports['default'] = _ember['default'].Controller.extend({
    userIsSaving: _ember['default'].computed.bool('model.user.isSaving'),
    emailIsSaving: false,

    otpState: _ember['default'].computed('model.user.otpEnabled', 'model.workingOtpConfiguration.otpUri', function () {
      var otpEnabled = this.get('model.user.otpEnabled');
      var workingOtpUri = this.get('model.workingOtpConfiguration.otpUri');
      if (otpEnabled) {
        return OTP_STATE_ENABLED;
      }
      if (workingOtpUri) {
        return OTP_STATE_PENDING;
      }
      return OTP_STATE_DISABLED;
    }),

    otpDisabled: _ember['default'].computed.equal('otpState', OTP_STATE_DISABLED),
    otpPending: _ember['default'].computed.equal('otpState', OTP_STATE_PENDING),
    otpEnabled: _ember['default'].computed.equal('otpState', OTP_STATE_ENABLED),
    otpReset: _ember['default'].computed.or('otpDisabled', 'otpPending'),

    showU2f: _ember['default'].computed.alias('otpEnabled'),
    hasU2f: _ember['default'].computed.gt('model.user.u2fDevices.length', 0),
    hasWebAuthn: _ember['default'].computed.gt('model.user.webauthnDevices.length', 0)
  });
});