define('diesel/models/scim-group', ['exports', 'ember-data', 'diesel/models/base'], function (exports, _emberData, _dieselModelsBase) {

  var ScimGroup = _dieselModelsBase['default'].extend({
    name: _emberData['default'].attr('string'),
    createdAt: _emberData['default'].attr('date'),
    updatedAt: _emberData['default'].attr('date'),
    organization: _emberData['default'].belongsTo('organization', { async: true }),
    role: _emberData['default'].belongsTo('role', { async: false })
  });

  exports['default'] = ScimGroup;
});