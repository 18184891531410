define('diesel/application/route', ['exports', 'ember', 'diesel/config/environment'], function (exports, _ember, _dieselConfigEnvironment) {

  // Defining an error action at all will cause errors to be swallowed.
  // This makes debugging in development impossible.  Use sentry.development
  // flag to determine if we should even define an error action.
  var onError = null;

  if (_dieselConfigEnvironment['default'].environment !== 'test' && !_dieselConfigEnvironment['default'].disableExceptionCapture) {
    onError = function (err) {
      _ember['default'].warn('Unhandled top-level error');
      _ember['default'].warn(err);
      if (!_dieselConfigEnvironment['default'].sentry.development) {
        this.get('raven').captureException(err);
      }
      this.intermediateTransitionTo('error', err);
    };
  }

  exports['default'] = _ember['default'].Route.extend({
    requireAuthentication: false,
    title: 'Conveyor Account Management',
    raven: _ember['default'].inject.service(),
    authorization: _ember['default'].inject.service(),
    actions: {
      accessDenied: function accessDenied() {
        this.transitionTo('login');
      },

      error: onError
    }
  });
});